define("ember-svg-jar/inlined/extension", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#fff\" d=\"M0 0h28v28H0z\"/><path clip-rule=\"evenodd\" d=\"M10.5.5c1.657 0 3 1.254 3 2.8l-1.263 2.358a2 2 0 00-.237.944V7.5h6.5a2 2 0 012 2V15h.898a2 2 0 00.945-.237L24.7 13.5c1.546 0 2.8 1.343 2.8 3s-1.254 3-2.8 3l-2.357-1.263a2 2 0 00-.945-.237H20.5v7.5a2 2 0 01-2 2h-3.497a2 2 0 01.234-.843L16.5 24.3c0-1.546-1.343-2.8-3-2.8s-3 1.254-3 2.8l1.263 2.357a2 2 0 01.234.843H2.5a2 2 0 01-2-2v-7.497c.294.015.582.095.842.234L3.7 19.5c1.546 0 2.8-1.343 2.8-3s-1.254-3-2.8-3l-2.358 1.263c-.26.14-.548.22-.842.234V9.5a2 2 0 012-2H9v-.898a2 2 0 00-.237-.944L7.5 3.3c0-1.546 1.343-2.8 3-2.8z\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});