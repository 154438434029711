define("ember-svg-jar/inlined/post-caption", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M25.292 0H2.708A2.722 2.722 0 000 2.726v18.62c0 1.48 1.193 2.727 2.708 2.727h6.794l3.81 3.742a.655.655 0 00.459.185h.458a.699.699 0 00.46-.185l3.81-3.742h6.793A2.722 2.722 0 0028 21.346V2.726C28 1.248 26.807 0 25.292 0zM3.874 21.532a1.213 1.213 0 01-1.207-1.218V3.618c0-.672.541-1.218 1.207-1.218h20.52c.666 0 1.207.546 1.207 1.218v16.738c0 .672-.541 1.176-1.207 1.176H17.86c-.166 0-.333.126-.416.21l-3.413 3.36-3.29-3.36c-.124-.126-.29-.21-.415-.21H3.874zM7.25 6a1.25 1.25 0 100 2.5h11.5a1.25 1.25 0 100-2.5H7.25zM6 12.25c0-.69.56-1.25 1.25-1.25h13.5a1.25 1.25 0 110 2.5H7.25c-.69 0-1.25-.56-1.25-1.25zM7.25 16a1.25 1.25 0 100 2.5h7.5a1.25 1.25 0 100-2.5h-7.5z\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});