define("ember-svg-jar/inlined/smart-hashtag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M15.736 25.6a1.1 1.1 0 010 2.2H8.1a1.1 1.1 0 010-2.2zM10.37.098c3.389-.45 6.517.673 8.777 2.733.199.182.059.516-.21.516H16.24a.382.382 0 01-.184-.048c-1.758-.983-3.887-1.38-6.135-.888-3.108.682-5.641 3.112-6.434 6.193-.845 3.282.22 6.522 2.562 8.645l.213.258 1.902 4.17h9.966l-1.03 1.665a1.101 1.101 0 01-.943.534H7.542c-.386 0-.744-.203-.943-.534l-2.14-4.567c-2.7-2.516-4.001-6.25-3.247-10.083C2.092 4.213 5.844.7 10.37.098zM24.266 4.75c.076 0 .154.007.234.021.687.184 1.125.798 1 1.474l-.375 1.597h1c.688 0 1.25.552 1.25 1.228 0 .675-.562 1.228-1.25 1.228h-1.5l-.75 3.685h1c.687 0 1.25.553 1.25 1.23 0 .674-.563 1.227-1.25 1.227h-1.437L23 18.528c-.125.553-.687.982-1.25.982h-.25c-.688-.183-1.125-.798-1-1.473l.375-1.597h-3.688l-.437 2.088c-.125.553-.688.982-1.25.982h-.25c-.687-.183-1.125-.798-1-1.473l.375-1.597h-1c-.688 0-1.25-.553-1.25-1.228 0-.676.562-1.229 1.25-1.229h1.5l.75-3.685h-1c-.688 0-1.25-.553-1.25-1.228 0-.676.562-1.228 1.25-1.228h1.5l.375-2.088c.166-.598.674-1.004 1.266-1.004.077 0 .155.007.234.021.688.184 1.125.798 1 1.474l-.312 1.597h3.687L23 5.754c.166-.599.675-1.004 1.266-1.004zm-2.141 5.548h-3.688l-.75 3.685h3.688l.75-3.685z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});