define("ember-svg-jar/inlined/skittle_twitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_276_3347)\"><circle cx=\"12\" cy=\"12\" r=\"12\" fill=\"#01ACED\"/><g clip-path=\"url(#clip1_276_3347)\"><path d=\"M10.105 16.959c4.873 0 7.538-3.816 7.538-7.124 0-.109-.003-.217-.008-.324a5.235 5.235 0 001.322-1.296c-.475.199-.986.333-1.521.394.547-.31.966-.8 1.165-1.385a5.504 5.504 0 01-1.683.608 2.72 2.72 0 00-1.934-.791c-1.463 0-2.65 1.12-2.65 2.503 0 .196.024.388.07.57-2.203-.104-4.155-1.1-5.462-2.615-.227.37-.359.8-.359 1.258 0 .869.468 1.636 1.18 2.085a2.749 2.749 0 01-1.2-.314v.032c0 1.213.912 2.225 2.125 2.455a2.796 2.796 0 01-1.197.043c.337.995 1.315 1.719 2.475 1.739a5.514 5.514 0 01-3.29 1.071c-.214 0-.425-.011-.633-.034a7.82 7.82 0 004.062 1.125\" fill=\"#fff\"/></g></g><defs><clipPath id=\"clip0_276_3347\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath><clipPath id=\"clip1_276_3347\"><path fill=\"#fff\" transform=\"translate(6 7)\" d=\"M0 0h13v10H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});