define("ember-svg-jar/inlined/cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.167 1.167a1.167 1.167 0 10-2.333 0v3.5h-1.75a5.25 5.25 0 100 10.5h1.75V21H7a1.167 1.167 0 100 2.333h5.834v3.5a1.167 1.167 0 102.333 0v-3.5h1.75a5.25 5.25 0 100-10.5h-1.75V7h4.667a1.167 1.167 0 000-2.333h-4.667v-3.5zM12.834 7h-1.75a2.917 2.917 0 100 5.833h1.75V7zm2.333 8.167V21h1.75a2.917 2.917 0 100-5.833h-1.75z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});