define("@latermedia/ember-later-analytics/models/analytics-social-profile", ["exports", "@latermedia/ember-later-analytics/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class AnalyticsSocialProfileModel {
    constructor(socialProfile) {
      _defineProperty(this, "platform", 'Instagram');
      _defineProperty(this, "defaultAvatarUrl", _constants.PLACEHOLDER_URL);
      _defineProperty(this, "defaultNickname", '');
      _defineProperty(this, "reportSlug", void 0);
      _defineProperty(this, "avatarUrl", void 0);
      _defineProperty(this, "profileUID", void 0);
      this.reportSlug = socialProfile?.nickname || this.defaultNickname;
      this.avatarUrl = socialProfile?.avatar_url || this.defaultAvatarUrl;
      this.profileUID = socialProfile?.uid;
    }
  }
  _exports.default = AnalyticsSocialProfileModel;
});