define("ember-svg-jar/inlined/planner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M5.444 2.333H21a1.556 1.556 0 010 3.111H5.444a1.556 1.556 0 110-3.11zM1.556 3.89A3.889 3.889 0 015.444 0H21a3.889 3.889 0 110 7.778H5.444a3.889 3.889 0 01-3.888-3.89zm11.666 7.778h10.89a1.556 1.556 0 110 3.11h-10.89a1.556 1.556 0 010-3.11zm-3.889 1.555a3.889 3.889 0 013.89-3.889H24.11a3.889 3.889 0 110 7.778H13.222a3.889 3.889 0 01-3.889-3.889zm-7 0a1.556 1.556 0 103.112 0 1.556 1.556 0 00-3.112 0zm1.557-3.89a3.889 3.889 0 100 7.778 3.889 3.889 0 000-7.778zM3.89 21h15.555a1.556 1.556 0 110 3.111H3.89a1.556 1.556 0 110-3.111zM0 22.556a3.889 3.889 0 013.889-3.89h15.555a3.889 3.889 0 110 7.778H3.89A3.889 3.889 0 010 22.556z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "27",
      "fill": "currentColor",
      "viewBox": "0 0 28 27"
    }
  };
});