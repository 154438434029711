define("ember-svg-jar/inlined/verified-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M14 .5C21.486.5 27.5 6.514 27.5 14S21.486 27.5 14 27.5.5 21.486.5 14 6.514.5 14 .5zm4 9.5l-4.982 5.982-2.3-2.577a1.186 1.186 0 00-1.718 0c-.49.49-.49 1.227 0 1.718l3.16 3.436c.49.491 1.226.491 1.717 0l5.841-6.84c.491-.492.491-1.228 0-1.719-.49-.49-1.35-.49-1.718 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});