define("ember-svg-jar/inlined/close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M24.649 3.436a1.54 1.54 0 01-.004 2.187l-8.418 8.419 8.418 8.42c.571.57.609 1.472.1 2.082l-.096.105a1.54 1.54 0 01-2.188-.004l-8.419-8.42-8.419 8.42a1.544 1.544 0 01-2.083.1l-.104-.096a1.54 1.54 0 01.004-2.188l8.418-8.42L3.44 5.624a1.544 1.544 0 01-.1-2.083l.096-.104a1.54 1.54 0 012.187.004l8.42 8.417L22.46 3.44a1.544 1.544 0 012.083-.1l.105.096z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});