define("ember-svg-jar/inlined/skittle_error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"12\" cy=\"12\" r=\"11.5\" fill=\"#fff\" stroke=\"url(#paint0_linear_282_3167)\"/><circle cx=\"12\" cy=\"12\" r=\"9\" fill=\"#D2D2D2\"/><path d=\"M11.005 7.1a1 1 0 111.99 0l-.388 6.35a.61.61 0 01-1.214 0l-.388-6.35zM11 16a1 1 0 112 0 1 1 0 01-2 0z\" fill=\"#fff\"/><defs><linearGradient id=\"paint0_linear_282_3167\" x1=\"12\" y1=\"0\" x2=\"12\" y2=\"24\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#FED446\"/><stop offset=\"1\" stop-color=\"#FF9B05\"/></linearGradient></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});