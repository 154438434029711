define("ember-svg-jar/inlined/lib-threads", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M18.337 13.954l-1.074-.186a12.733 12.733 0 00-2.912-.14c-1.633.096-2.69.697-3.228 1.394-.356.46-.554 1.041-.517 1.759.045.858.448 1.575 1.18 2.067m-2.262-2.01c-.05-.967.22-1.805.742-2.479.776-1.004 2.161-1.703 4.022-1.812.997-.058 1.93-.025 2.798.097.122.018.242.036.362.057l.766.133-.126-.781a6.759 6.759 0 00-.066-.352c-.162-.756-.447-1.405-.874-1.911-.696-.823-1.736-1.196-2.984-1.204-.579-.004-2.012.247-3.115 1.287l-1.006-.703c.235-.29.496-.55.783-.774.887-.698 2.012-1.073 3.302-1.073.921-.052 2.522.131 3.748 1.432.86.913 1.398 2.287 1.496 4.123l.018.274c.057.009.125.028.203.056.909.326 3.126 1.908 3.602 3.493.795 1.89.873 4.987-1.567 7.47C19.646 26.19 17.981 27 14.093 27c-3.761-.027-6.58-1.31-8.467-3.752-1.69-2.19-2.595-5.279-2.626-9.243v-.01c.014-1.795.207-3.413.572-4.85.44-1.73 1.13-3.196 2.054-4.393a9.093 9.093 0 014.045-2.994c1.288-.489 2.762-.746 4.422-.758h.012c3.77.027 6.627 1.305 8.573 3.744a11.11 11.11 0 011.628 2.838c.132.33.252.672.36 1.025l-1.17.318c-.406-1.292-.982-2.429-1.73-3.366-2.199-2.869-6.03-3.392-7.671-3.296-3.285.025-5.858 1.105-7.545 3.288-.83 1.076-1.436 2.391-1.825 3.92-.202.947-.59 3.18-.531 4.534.028 3.558.796 6.423 2.356 8.444 1.687 2.183 4.26 3.263 7.545 3.288h.008c2.955-.022 4.998-.746 6.698-2.476 1.976-2.012 1.952-4.505 1.303-6.046-.104-.45-.796-1.38-1.706-2.153a6.961 6.961 0 00-1.012-.723l-.113 1.05-.037.14c-.297 1.083-.723 2.302-1.255 2.993-.834 1.082-2 1.95-3.59 2.039-1.205.067-2.343-.231-3.21-.814-1.012-.68-1.594-1.709-1.657-2.91zm2.262 2.01c.648.436 1.547.686 2.544.631 1.184-.066 2.086-.7 2.793-1.618.397-.516.784-1.574 1.082-2.671l.133-1.236\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});