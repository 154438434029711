define("@latermedia/ember-later-analytics/components/ui/post-card-item", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="o--cardContainer tRE--cardContainer"
    data-test-id="post-card-item"
    data-media-id={{@post.id}}
    data-platform-link={{@post.link}}
  >
  
    <BestPerformingMedia::Item
      @media={{@post}}
      @isLoading={{@isLoading}}
      @handleError={{@handleError}}
      @isReport={{@isReport}}
    >
  
      <BestPerformingMedia::Number
        @isLoading={{@isLoading}}
        @showLoadingState={{true}}
        @text={{t "best_posts.reach"}}
        @number={{@post.reachCount}}
        @isReport={{@isReport}}
      />
  
      <BestPerformingMedia::Number
        @isLoading={{@isLoading}}
        @showLoadingState={{true}}
        @text={{t "best_posts.likes"}}
        @number={{@post.likeCount}}
        @isReport={{@isReport}}
      />
  
      <BestPerformingMedia::Number
        @isLoading={{@isLoading}}
        @showLoadingState={{true}}
        @text={{t "best_posts.comments"}}
        @number={{@post.commentCount}}
        @isReport={{@isReport}}
      />
      
      <BestPerformingMedia::Number
        @isLoading={{@isLoading}}
        @showLoadingState={{true}}
        @text={{t "best_posts.engagement"}}
        @number={{@post.engagementPercentage}}
        @isReport={{@isReport}}
      />
      
      <BestPerformingMedia::Number
        @isLoading={{@isLoading}}
        @showLoadingState={{true}}
        @text={{t "best_posts.lib_clicks"}}
        @number={{@post.libClicksCount}}
        @isReport={{@isReport}}
      />
        
    </BestPerformingMedia::Item>
  </div>
  */
  {
    "id": "/3LbTnTP",
    "block": "[[[10,0],[14,0,\"o--cardContainer tRE--cardContainer\"],[14,\"data-test-id\",\"post-card-item\"],[15,\"data-media-id\",[30,1,[\"id\"]]],[15,\"data-platform-link\",[30,1,[\"link\"]]],[12],[1,\"\\n\\n  \"],[8,[39,0],null,[[\"@media\",\"@isLoading\",\"@handleError\",\"@isReport\"],[[30,1],[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@isLoading\",\"@showLoadingState\",\"@text\",\"@number\",\"@isReport\"],[[30,2],true,[28,[37,2],[\"best_posts.reach\"],null],[30,1,[\"reachCount\"]],[30,4]]],null],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@isLoading\",\"@showLoadingState\",\"@text\",\"@number\",\"@isReport\"],[[30,2],true,[28,[37,2],[\"best_posts.likes\"],null],[30,1,[\"likeCount\"]],[30,4]]],null],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@isLoading\",\"@showLoadingState\",\"@text\",\"@number\",\"@isReport\"],[[30,2],true,[28,[37,2],[\"best_posts.comments\"],null],[30,1,[\"commentCount\"]],[30,4]]],null],[1,\"\\n    \\n    \"],[8,[39,1],null,[[\"@isLoading\",\"@showLoadingState\",\"@text\",\"@number\",\"@isReport\"],[[30,2],true,[28,[37,2],[\"best_posts.engagement\"],null],[30,1,[\"engagementPercentage\"]],[30,4]]],null],[1,\"\\n    \\n    \"],[8,[39,1],null,[[\"@isLoading\",\"@showLoadingState\",\"@text\",\"@number\",\"@isReport\"],[[30,2],true,[28,[37,2],[\"best_posts.lib_clicks\"],null],[30,1,[\"libClicksCount\"]],[30,4]]],null],[1,\"\\n      \\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@post\",\"@isLoading\",\"@handleError\",\"@isReport\"],false,[\"best-performing-media/item\",\"best-performing-media/number\",\"t\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/ui/post-card-item.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});