define("ember-svg-jar/inlined/creator-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M25.248 15.909c.279-.302.502-.66.651-1.064l.452-1.22 1.221-.453c.136-.05.267-.109.393-.175a14.176 14.176 0 01.03 1.376C27.797 21.933 21.607 28 14 28 6.268 28 0 21.732 0 14S6.268 0 14 0a13.935 13.935 0 019.203 3.45 3.195 3.195 0 00-1.665.294c-.419.198-.79.487-1.087.847A11.355 11.355 0 0014 2.593C7.7 2.593 2.593 7.7 2.593 14a11.36 11.36 0 003.674 8.387 5.186 5.186 0 015.14-4.498h5.186a5.186 5.186 0 015.14 4.498c.378-.35.733-.724 1.06-1.12a11.36 11.36 0 002.455-5.358zM18.82 11.62l1.934.717.716 1.934a1.519 1.519 0 001.439 1.002 1.518 1.518 0 001.439-1.002l.716-1.934 1.934-.716A1.519 1.519 0 0028 10.18a1.518 1.518 0 00-1.002-1.439l-1.934-.715-.716-1.934a1.52 1.52 0 00-1.439-1.002 1.518 1.518 0 00-1.439 1.002l-.716 1.934-1.934.716a1.519 1.519 0 00-1.002 1.439 1.518 1.518 0 001.002 1.439zm-2.18-3.122a3.176 3.176 0 01.917-.953A5.421 5.421 0 0014 6.222a5.444 5.444 0 105.128 7.277l-.882-.327a3.235 3.235 0 01-1.075-.66 3.162 3.162 0 01-.355-.392 2.85 2.85 0 01-5.668-.453 2.852 2.852 0 015.035-1.836 3.2 3.2 0 01.458-1.333zm5.332.998a1.536 1.536 0 01-.42.235l-1.217.451 1.218.45a1.53 1.53 0 01.669.486l.005.005c.097.125.176.265.232.417l.45 1.216.45-1.217c.056-.151.135-.291.233-.416l.002-.003a1.428 1.428 0 01.25-.25l.002-.002c.125-.099.266-.179.42-.235l1.217-.451-1.218-.45a1.53 1.53 0 01-.67-.487l-.001-.002a1.533 1.533 0 01-.235-.42l-.45-1.215-.45 1.217c-.055.15-.133.29-.23.413l-.004.005a1.423 1.423 0 01-.25.251l-.003.002zM8.815 24.164A11.36 11.36 0 0014 25.407c1.867 0 3.63-.448 5.185-1.243v-1.09a2.593 2.593 0 00-2.592-2.593h-5.186a2.593 2.593 0 00-2.592 2.593v1.09z\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});