define("ember-svg-jar/inlined/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M27.618 25.836l-7.254-7.254c1.527-1.91 2.545-4.455 2.545-7.127C22.91 5.09 17.82 0 11.455 0A11.407 11.407 0 000 11.455c0 6.363 5.09 11.454 11.455 11.454 2.672 0 5.218-.89 7.127-2.545l7.254 7.254c.255.255.637.382.891.382.255 0 .637-.127.891-.382a1.23 1.23 0 000-1.782zm-16.163-5.472a8.854 8.854 0 01-8.91-8.91 8.854 8.854 0 018.91-8.909 8.854 8.854 0 018.909 8.91 8.854 8.854 0 01-8.91 8.909z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});