define("ember-svg-jar/inlined/layout-option-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\"><path fill=\"#DCE4EC\" d=\"M17.158 0c.465 0 .842.377.842.842v6.316a.842.842 0 01-.842.842h-6.316A.842.842 0 0110 7.158V.842c0-.465.377-.842.842-.842h6.316zm10 0c.465 0 .842.377.842.842v6.316a.842.842 0 01-.842.842h-6.316A.842.842 0 0120 7.158V.842c0-.465.377-.842.842-.842h6.316zm-20 0C7.623 0 8 .377 8 .842v6.316A.842.842 0 017.158 8H.842A.842.842 0 010 7.158V.842C0 .377.377 0 .842 0h6.316z\"/><path fill=\"#000\" fill-opacity=\".2\" d=\"M17.158 0c.465 0 .842.377.842.842v6.316a.842.842 0 01-.842.842h-6.316A.842.842 0 0110 7.158V.842c0-.465.377-.842.842-.842h6.316zm10 0c.465 0 .842.377.842.842v6.316a.842.842 0 01-.842.842h-6.316A.842.842 0 0120 7.158V.842c0-.465.377-.842.842-.842h6.316zm-20 0C7.623 0 8 .377 8 .842v6.316A.842.842 0 017.158 8H.842A.842.842 0 010 7.158V.842C0 .377.377 0 .842 0h6.316z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "8",
      "viewBox": "0 0 28 8"
    }
  };
});