define("ember-svg-jar/inlined/lib-instagram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.989 1.75c-3.991 0-7.239 3.248-7.239 7.239V19.01c0 3.991 3.248 7.239 7.239 7.239H19.01c3.991 0 7.239-3.248 7.239-7.239V8.99c0-3.991-3.248-7.239-7.239-7.239H8.99zm0 1.114H19.01a6.117 6.117 0 016.125 6.125V19.01a6.117 6.117 0 01-6.125 6.125H8.99a6.117 6.117 0 01-6.125-6.125V8.99A6.117 6.117 0 018.99 2.865zm11.693 3.34a1.114 1.114 0 100 2.228 1.114 1.114 0 000-2.227zM14 7.876A6.134 6.134 0 007.875 14 6.134 6.134 0 0014 20.125 6.134 6.134 0 0020.125 14 6.134 6.134 0 0014 7.875zm0 1.114A5.003 5.003 0 0119.011 14 5.003 5.003 0 0114 19.011 5.003 5.003 0 018.989 14 5.003 5.003 0 0114 8.989z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});