define("ember-svg-jar/inlined/social-youtube", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M27.415 7.08a3.518 3.518 0 00-2.476-2.491C22.755 4 14 4 14 4s-8.756 0-10.94.589A3.519 3.519 0 00.586 7.08C0 9.277 0 13.864 0 13.864s0 4.585.585 6.783a3.519 3.519 0 002.476 2.492c2.184.588 10.939.588 10.939.588s8.756 0 10.94-.588a3.518 3.518 0 002.474-2.492C28 18.45 28 13.864 28 13.864s0-4.585-.585-6.784zM11.136 18.027V9.7l7.319 4.164-7.319 4.163z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});