define("ember-svg-jar/inlined/social-facebook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.288 5.423v3.854H7.465v4.714h2.823V28h5.801V13.993h3.894s.364-2.261.542-4.732h-4.413V6.036c0-.48.633-1.129 1.258-1.129h3.162V0h-4.298c-6.088 0-5.946 4.718-5.946 5.423\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});