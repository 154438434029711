define("ember-svg-jar/inlined/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.005 0C20.552 0 25.86 5.308 25.86 11.855c0 1.686-.644 3.476-1.798 5.394-.927 1.542-2.173 3.143-3.66 4.777a54.868 54.868 0 01-3.529 3.524 53.288 53.288 0 01-1.624 1.425l-1.244 1.024-1.244-1.024a53.288 53.288 0 01-1.624-1.425 54.868 54.868 0 01-3.529-3.524c-1.487-1.634-2.733-3.235-3.66-4.777-1.154-1.918-1.798-3.708-1.798-5.394C2.15 5.408 7.296.164 13.704.004L14.005 0zm.068 2.485a9.387 9.387 0 00-9.387 9.387c0 5.185 9.387 12.908 9.387 12.908s9.387-7.723 9.387-12.908a9.387 9.387 0 00-9.387-9.387zm-.056 3.6a5.072 5.072 0 01.22 10.14l-.22.004a5.072 5.072 0 010-10.143zm-.026 2.03a3.016 3.016 0 00-.177 6.027l.177.005a3.016 3.016 0 000-6.033z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});