define("@latermedia/ember-later-analytics/utils/media", ["exports", "@latermedia/ember-later-analytics/utils", "@latermedia/ember-later-analytics/utils/object-methods", "@ember/utils", "@ember/array"], function (_exports, _utils, _objectMethods, _utils2, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDefaultStoriesHighlights = _exports.getDefaultReelsHighlights = _exports.getDefaultPostsHighlights = _exports.formatStoriesSummary = _exports.formatStoriesHighlights = _exports.formatReelsSummary = _exports.formatReelsHighlights = _exports.formatPostsSummary = _exports.formatPostsHighlights = void 0;
  /**
   * @module Utils
   */

  /**
   * @class media
   * @extends Utils
   */

  const NUMBER_OF_MEDIA_HIGHLIGHTS = 5;
  const DEFAULT_POST = {
    url: null,
    link: '',
    reachCount: '-',
    likeCount: '-',
    commentCount: '-',
    engagementPercentage: '-',
    libClicksCount: '-'
  };
  const DEFAULT_STORY = {
    url: null,
    link: '',
    reachCount: '-',
    impressionsCount: '-'
  };
  const DEFAULT_REEL = {
    url: null,
    link: '',
    likeCount: '-',
    reachCount: '-',
    playsCount: '-'
  };
  const EMPTY_STORY = {
    url: null,
    link: '',
    reachCount: '-',
    impressionsCount: '-',
    imageUrl: '',
    reach: undefined,
    impressions: undefined
  };
  const EMPTY_POST = {
    engagementPercentage: '',
    libClicksCount: '',
    likeCount: '',
    link: '',
    reachCount: '',
    url: null,
    commentCount: '',
    comments: undefined,
    engagement: undefined,
    hasLinkinbio: undefined,
    imageUrl: undefined,
    libClicks: undefined,
    likes: undefined,
    rank: undefined,
    reach: undefined,
    impressions: undefined
  };
  const EMPTY_REEL = {
    rank: undefined,
    reach: undefined,
    reachCount: undefined,
    likes: undefined,
    likesCount: undefined,
    plays: undefined,
    playsCount: undefined,
    url: null,
    link: '',
    imageUrl: ''
  };

  /**
   * Generates an array of empty post highlight media
   *
   * @method getDefaultPostsHighlights
   *
   * @returns {Array.<Object>}
   */
  const getDefaultPostsHighlights = () => new Array(NUMBER_OF_MEDIA_HIGHLIGHTS).fill(DEFAULT_POST);

  /**
   * Generates an array of empty story highlight media
   *
   * @method getDefaultStoriesHighlights
   *
   * @returns {Array.<Object>}
   */
  _exports.getDefaultPostsHighlights = getDefaultPostsHighlights;
  const getDefaultStoriesHighlights = () => new Array(NUMBER_OF_MEDIA_HIGHLIGHTS).fill(DEFAULT_STORY);

  /**
   * Generates an array of empty reel highlight media
   *
   * @method getDefaultReelsHighlights
   *
   * @returns {Array.<Object>}
   */
  _exports.getDefaultStoriesHighlights = getDefaultStoriesHighlights;
  const getDefaultReelsHighlights = () => new Array(NUMBER_OF_MEDIA_HIGHLIGHTS).fill(DEFAULT_REEL);

  /**
   * Formats raw post summary to include formatted, required fields
   *
   * @method formatPostsSummary
   * @param {Object} summary raw post summary object
   *
   * @returns {Object} formatted post summary object
   */
  _exports.getDefaultReelsHighlights = getDefaultReelsHighlights;
  const formatPostsSummary = summary => ({
    averageReachCount: summary?.median_reach ? Number(summary?.median_reach) : null,
    averageLikesCount: summary?.median_likes ? Number(summary?.median_likes) : null,
    averageCommentsCount: summary?.median_comments ? Number(summary?.median_comments) : null,
    averageEngagementPercentage: summary?.median_engagement ? Number(summary?.median_engagement) * 100 : null,
    averageImpressionsCount: summary?.median_impressions ? Number(summary?.median_impressions) : null,
    averageSharesCount: summary?.median_shares ? Number(summary?.median_shares) : null
  });

  /**
   * Formats raw story summary to include formatted, required fields
   *
   * @method formatStoriesSummary
   * @param {Object} summary raw story summary object
   *
   * @returns {Object} formatted story summary object
   */
  _exports.formatPostsSummary = formatPostsSummary;
  const formatStoriesSummary = summary => ({
    averageImpressionsCount: summary?.median_impressions ? Number(summary?.median_impressions) : null,
    averageReachCount: summary?.median_reach ? Number(summary?.median_reach) : null,
    averageRepliesCount: summary?.median_replies ? Number(summary?.median_replies) : null
  });

  /**
   * Formats raw reels summary to include formatted, required fields
   *
   * @method formatReelsSummary
   * @param {Object} summary raw reel summary object
   *
   * @returns {Object} formatted reel summary object
   */
  _exports.formatStoriesSummary = formatStoriesSummary;
  const formatReelsSummary = summary => ({
    averageReachCount: summary?.median_reach ? Number(summary?.median_reach) : null,
    averagePlayCount: summary?.median_plays ? Number(summary?.median_plays) : null,
    averageLikeCount: summary?.average_likes ? Number(summary?.average_likes) : null
  });

  /**
   * Formats array of top highlighted posts,
   * such that each post has required formatted fields
   *
   * @method formatPostsHighlights
   * @param {Array.<Object>} highlights raw array of post highlights
   *
   * @returns {Object} formatted array of post highlights
   */
  _exports.formatReelsSummary = formatReelsSummary;
  const formatPostsHighlights = (highlights, config) => {
    const postsHighlights = (0, _array.A)(highlights?.map(media => {
      const formattedMedia = (0, _objectMethods.objectSnakeToCamel)(media, EMPTY_POST);
      formattedMedia.url = formattedMedia.imageUrl;
      formattedMedia.link;
      formattedMedia.reachCount = formattedMedia.reach;
      formattedMedia.likeCount = formattedMedia.likes;
      formattedMedia.commentCount = formattedMedia.comments;
      formattedMedia.engagementPercentage = formattedMedia.engagement ? `${(0, _utils.preciseRound)(Number(formattedMedia.engagement) * 100, 2)}%` : null;
      formattedMedia.libClicksCount = (0, _utils2.isPresent)(formattedMedia.libClicks) && formattedMedia.hasLinkinbio ? formattedMedia.libClicks : DEFAULT_POST.libClicksCount;
      return formattedMedia;
    }))?.sortBy('rank');
    if (config.generateDefaultHighlights) {
      const defaultPosts = getDefaultPostsHighlights();
      return (0, _utils2.isEmpty)(postsHighlights) ? defaultPosts : defaultPosts.map((defaultPost, index) => postsHighlights[index] || defaultPost);
    }
    return postsHighlights;
  };

  /**
   * Formats array of top highlighted stories,
   * such that each story has required formatted fields
   *
   * @method formatStoriesHighlights
   * @param {Array.<Object>} highlights raw array of story highlights
   *
   * @returns {Object} formatted array of story highlights
   */
  _exports.formatPostsHighlights = formatPostsHighlights;
  const formatStoriesHighlights = (highlights, config) => {
    const storiesHighlights = (0, _array.A)(highlights?.map(media => {
      const formattedMedia = (0, _objectMethods.objectSnakeToCamel)(media, EMPTY_STORY);
      formattedMedia.url = formattedMedia.imageUrl;
      formattedMedia.link = '';
      formattedMedia.reachCount = formattedMedia.reach;
      formattedMedia.impressionsCount = formattedMedia.impressions;
      return formattedMedia;
    }))?.sortBy('rank');
    if (config.generateDefaultHighlights) {
      const defaultStories = getDefaultStoriesHighlights();
      return (0, _utils2.isEmpty)(storiesHighlights) ? defaultStories : defaultStories.map((defaultStory, index) => storiesHighlights[index] || defaultStory);
    }
    return storiesHighlights;
  };

  /**
   * Formats array of top highlighted reels,
   * such that each reel has required formatted fields
   *
   * @method formatReelsHighlights
   * @param {Array.<Object>} highlights raw array of reel highlights
   *
   * @returns {Object} formatted array of reel highlights
   */
  _exports.formatStoriesHighlights = formatStoriesHighlights;
  const formatReelsHighlights = (highlights, config) => {
    const reelsHighlights = (0, _array.A)(highlights?.map(media => {
      const formattedMedia = (0, _objectMethods.objectSnakeToCamel)(media, EMPTY_REEL);
      formattedMedia.url = formattedMedia.imageUrl;
      formattedMedia.likesCount = media.likes;
      formattedMedia.reachCount = media.reach;
      formattedMedia.playsCount = formattedMedia.plays;
      return formattedMedia;
    }))?.sortBy('rank');
    if (config.generateDefaultHighlights) {
      const defaultReels = getDefaultReelsHighlights();
      return (0, _utils2.isEmpty)(reelsHighlights) ? defaultReels : defaultReels.map((defaultReel, index) => reelsHighlights[index] || defaultReel);
    }
    return reelsHighlights;
  };
  _exports.formatReelsHighlights = formatReelsHighlights;
});