define("ember-svg-jar/inlined/lib-btn-shadow-hard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g filter=\"url(#filter0_d_1997_57831)\"><path d=\"M4 5h16v16H4z\"/></g><defs><filter id=\"filter0_d_1997_57831\" width=\"19\" height=\"19\" x=\"4\" y=\"5\" color-interpolation-filters=\"sRGB\" filterUnits=\"userSpaceOnUse\"><feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/><feColorMatrix in=\"SourceAlpha\" result=\"hardAlpha\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\"/><feOffset dx=\"3\" dy=\"3\"/><feComposite in2=\"hardAlpha\" operator=\"out\"/><feColorMatrix values=\"0 0 0 0 0.196078 0 0 0 0 0.231373 0 0 0 0 0.262745 0 0 0 1 0\"/><feBlend in2=\"BackgroundImageFix\" result=\"effect1_dropShadow_1997_57831\"/><feBlend in=\"SourceGraphic\" in2=\"effect1_dropShadow_1997_57831\" result=\"shape\"/></filter></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});