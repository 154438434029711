define("@latermedia/ember-later-analytics/components/ui/pie-chart-loading", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cCH--loading cCH--loading--circle" data-test-id="pie-chart-loading"></div>
  */
  {
    "id": "ZkfVYdir",
    "block": "[[[10,0],[14,0,\"cCH--loading cCH--loading--circle\"],[14,\"data-test-id\",\"pie-chart-loading\"],[12],[13]],[],false,[]]",
    "moduleName": "@latermedia/ember-later-analytics/components/ui/pie-chart-loading.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});