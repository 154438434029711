define("ember-svg-jar/inlined/skittle_linkedin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12z\" fill=\"#0072B1\"/><path d=\"M16.06 19H19v-5.77c0-2.849-1.772-3.82-3.412-3.82-1.517 0-2.547.98-2.831 1.554V9.672H9.93V19h2.939v-5.058c0-1.348.855-2.004 1.727-2.004.825 0 1.465.464 1.465 1.967V19zM5 6.686c0 .974.759 1.686 1.694 1.686.936 0 1.695-.712 1.695-1.686C8.39 5.713 7.63 5 6.695 5 5.758 5 5 5.713 5 6.686zM5.225 18.993h2.939V9.664H5.225v9.329z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});