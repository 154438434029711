define("ember-svg-jar/inlined/canva", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M28 14c0 7.732-6.268 14-14 14S0 21.732 0 14 6.268 0 14 0s14 6.268 14 14zm-8.279 3.183c.106-.213.208-.311.323-.311.167 0 .328.143.328.457 0 1.415-2.534 5.773-6.915 5.773-4.127 0-6.802-3.095-6.802-7.78 0-6.052 4.193-10.426 8.91-10.426 2.856 0 4.557 1.437 4.557 3.392 0 2.017-1.458 3.444-2.7 3.444-.31 0-.465-.152-.465-.402 0-.208.125-.455.281-.765.264-.522.619-1.222.619-2.215 0-1.334-.815-2.173-2.15-2.173-2.854 0-6.104 3.373-6.104 9.206 0 3.444 1.71 5.937 4.471 5.937 2.388 0 4.452-1.715 5.647-4.137z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});