define("ember-svg-jar/inlined/arrow-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M26.6 12.8H4.76l7.42-7.42c.56-.56.56-1.4 0-1.96s-1.4-.56-1.96 0l-9.8 9.8c-.56.56-.56 1.4 0 1.96l9.8 9.8c.28.28.7.42.98.42.42 0 .7-.14.98-.42.56-.56.56-1.4 0-1.96L4.76 15.6H26.6c.84 0 1.4-.56 1.4-1.4 0-.84-.7-1.4-1.4-1.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});