define("ember-svg-jar/inlined/ellipsis-h-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M26.759-8.758L24.99-6.99l-5.882-5.882-9.49 9.491-1.768-1.767L19.11-16.407l7.649 7.649zM4.182 11C2.095 11 .5 12.595.5 14.682c0 2.086 1.595 3.682 3.682 3.682 2.086 0 3.682-1.596 3.682-3.682C7.864 12.595 6.268 11 4.182 11zM14 11c-2.086 0-3.682 1.595-3.682 3.682 0 2.086 1.596 3.682 3.682 3.682s3.682-1.596 3.682-3.682C17.682 12.595 16.086 11 14 11zm9.818 0c-2.086 0-3.682 1.595-3.682 3.682 0 2.086 1.596 3.682 3.682 3.682 2.087 0 3.682-1.596 3.682-3.682 0-2.087-1.595-3.682-3.682-3.682z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});