define("ember-svg-jar/inlined/skittle_blog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_276_3346)\"><circle cx=\"12\" cy=\"12\" r=\"12\" fill=\"#FFB301\"/><g clip-path=\"url(#clip1_276_3346)\" fill=\"#fff\"><path d=\"M8.611 13.776a1.592 1.592 0 101.598 1.595 1.6 1.6 0 00-1.598-1.595z\"/><path d=\"M7.014 9.658v2.283c1.371 0 2.689.406 3.658 1.375.968.968 1.371 2.283 1.371 3.658h2.286c0-3.998-3.314-7.316-7.315-7.316z\"/><path d=\"M7.014 6v2.284c4.886 0 8.684 3.8 8.684 8.69h2.29C17.987 10.924 13.071 6 7.013 6z\"/></g></g><defs><clipPath id=\"clip0_276_3346\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath><clipPath id=\"clip1_276_3346\"><path fill=\"#fff\" transform=\"translate(7 6)\" d=\"M0 0h11v11H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});