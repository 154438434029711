define("ember-svg-jar/inlined/conversations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M20.886 4.452c1.63 0 2.877 1.26 2.877 2.906v13.21c0 1.647-1.247 2.906-2.877 2.906h-8.899s-3.093 2.285-6.168 4.45c-.246.154-.508.071-.544-.224v-4.226H3.053c-1.63 0-2.877-1.26-2.877-2.906V7.358c0-1.647 1.247-2.906 2.877-2.906zm-.13 1.967H3.183c-.567 0-.945.386-.945.965v13.168c0 .58.378.965.945.965h4.08v2.993l4.157-2.993h9.336c.567 0 .945-.386.945-.965l.002-13.168c0-.58-.38-.965-.947-.965zM24.768.532c1.63 0 2.877 1.259 2.877 2.905v13.21c0 1.647-1.247 2.906-2.877 2.906h-.135l.002-2.038c.462-.042.776-.359.82-.829l.006-.12.003-13c0-.572-.376-.953-.936-.953H7.174c-.56 0-.933.38-.933.953v.042H4.056l.001-.17c0-1.588 1.16-2.816 2.704-2.901l.173-.005z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});