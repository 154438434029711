define("ember-svg-jar/inlined/FEATURES", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.037 12.917a2.61 2.61 0 001.626 2.418l5.162 2.1 2.1 5.163a2.61 2.61 0 004.833 0l2.1-5.163 5.162-2.1a2.61 2.61 0 000-4.832l-5.157-2.105-2.101-5.162a2.61 2.61 0 00-4.832 0L6.825 8.398l-5.162 2.1a2.61 2.61 0 00-1.626 2.419zm8.789-2.518l2.518-6.18 2.518 6.18 6.18 2.518-6.18 2.518-2.518 6.18-2.518-6.18-6.18-2.518 6.18-2.518zM21.48 0l-1.232 2.248L18 3.48l2.248 1.23 1.231 2.25 1.23-2.25 2.25-1.23-2.25-1.23L21.48 0zM21.48 19l-1.232 2.248L18 22.48l2.248 1.23 1.231 2.25 1.23-2.25 2.25-1.23-2.25-1.23L21.48 19z\" fill=\"#9E52FF\"/><path d=\"M8.83 10.43l2.518-6.18 2.518 6.18 6.18 2.518-6.18 2.518-2.518 6.18-2.518-6.18-6.18-2.518 6.18-2.518z\" fill=\"#fff\" fill-opacity=\".5\"/>",
    "attrs": {
      "width": "25",
      "height": "26",
      "viewBox": "0 0 25 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});