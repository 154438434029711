define("ember-svg-jar/inlined/skittle_review", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"12\" cy=\"12\" r=\"12\" fill=\"#1AAA8E\"/><path d=\"M18 10.588h-4.591L12 6l-1.409 4.588H6l3.755 2.824L8.293 18 12 15.156 15.707 18l-1.465-4.588L18 10.588z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});