define("ember-svg-jar/inlined/lib-btn-shadow-soft", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g filter=\"url(#filter0_d_2000_56973)\"><path d=\"M4 5h16v16H4z\"/></g><defs><filter id=\"filter0_d_2000_56973\" width=\"22\" height=\"22\" x=\"3\" y=\"4\" color-interpolation-filters=\"sRGB\" filterUnits=\"userSpaceOnUse\"><feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/><feColorMatrix in=\"SourceAlpha\" result=\"hardAlpha\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\"/><feOffset dx=\"2\" dy=\"2\"/><feGaussianBlur stdDeviation=\"1.5\"/><feComposite in2=\"hardAlpha\" operator=\"out\"/><feColorMatrix values=\"0 0 0 0 0.196078 0 0 0 0 0.231373 0 0 0 0 0.262745 0 0 0 0.6 0\"/><feBlend in2=\"BackgroundImageFix\" result=\"effect1_dropShadow_2000_56973\"/><feBlend in=\"SourceGraphic\" in2=\"effect1_dropShadow_2000_56973\" result=\"shape\"/></filter></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});