define("ember-svg-jar/inlined/lib-featured-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#a)\"><path fill-rule=\"evenodd\" d=\"M2.715 5.09A2.715 2.715 0 000 7.807v16.63c0 1.5 1.216 2.715 2.715 2.715h16.63c1.5 0 2.716-1.215 2.716-2.715V7.806c0-1.5-1.216-2.715-2.715-2.715H2.716zm16.63 2.037H2.716a.679.679 0 00-.679.68v16.63c0 .374.304.678.68.678h16.63a.679.679 0 00.678-.679V7.806a.679.679 0 00-.678-.679z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M9.28 13.45l4.318 2.67-4.318 2.671V13.45zm-2.068 6.943c0 .453.157.892.543 1.115.39.224.847.133 1.234-.104l6.885-4.228c.394-.242.671-.611.671-1.055 0-.443-.276-.814-.67-1.056l-6.886-4.228c-.387-.237-.844-.327-1.233-.103-.386.223-.544.66-.544 1.114v8.545z\" clip-rule=\"evenodd\"/><path d=\"M8.655 2.036h16.63a.68.68 0 01.679.68v12.627L22.06 11.44v3l3.903 3.903v1.003a.679.679 0 01-.68.678H22.06v2.037h3.224c1.5 0 2.715-1.216 2.715-2.715V2.716A2.714 2.714 0 0025.285 0H8.655a2.715 2.715 0 00-2.716 2.715v2.376h2.037V2.715c0-.375.304-.679.679-.679z\"/><path d=\"M22.06 9.388c1.512-.18 2.623-1.412 2.623-2.986 0-1.704-1.303-3.008-3.008-3.008-1.284 0-2.341.74-2.784 1.834.68.177 1.294.519 1.796.98.078-.492.454-.809.988-.809.602 0 1.003.402 1.003 1.003 0 .602-.401 1.003-1.003 1.003-.027 0-.053 0-.078-.002.296.579.464 1.235.464 1.93v.055z\"/></g><defs><clipPath id=\"a\"><path d=\"M0 0h28v28H0z\"/></clipPath></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});