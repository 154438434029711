define("ember-svg-jar/inlined/skittle_ig_reel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12z\" fill=\"url(#paint0_radial_276_3340)\"/><path d=\"M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12z\" fill=\"url(#paint1_radial_276_3340)\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.14 8.4h2.815A4.001 4.001 0 0015 5h-.817l1.958 3.4zM12.48 5l1.958 3.4H11.14L9.183 5h3.296zm-4.86.245A4.005 4.005 0 005.046 8.4h4.392L7.62 5.245zM5 15V9.9h14V15a4 4 0 01-4 4H9a4 4 0 01-4-4zm9.1-.457a.8.8 0 000-1.386l-2.775-1.602a.8.8 0 00-1.2.693v3.204a.8.8 0 001.2.693l2.775-1.602z\" fill=\"#fff\"/><defs><radialGradient id=\"paint0_radial_276_3340\" cx=\"0\" cy=\"0\" r=\"1\" gradientUnits=\"userSpaceOnUse\" gradientTransform=\"matrix(0 -23.7858 22.1226 0 6.375 25.849)\"><stop stop-color=\"#FD5\"/><stop offset=\".1\" stop-color=\"#FD5\"/><stop offset=\".5\" stop-color=\"#FF543E\"/><stop offset=\"1\" stop-color=\"#C837AB\"/></radialGradient><radialGradient id=\"paint1_radial_276_3340\" cx=\"0\" cy=\"0\" r=\"1\" gradientUnits=\"userSpaceOnUse\" gradientTransform=\"rotate(78.681 -3.065 -1.588) scale(10.6323 43.827)\"><stop stop-color=\"#3771C8\"/><stop offset=\".128\" stop-color=\"#3771C8\"/><stop offset=\"1\" stop-color=\"#60F\" stop-opacity=\"0\"/></radialGradient></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});