define("@latermedia/ember-later-analytics/components/ui/media-kit/story-card-item", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cPR--thumbnail qa--mediaKit_clientPage_igFeedPerformance_pic">
    {{#if @story.imageUrl}}
      <figure class="o--mediaContainer cPR--thumbnail__image">
        <img src={{@story.imageUrl}} class="o--media" onerror={{set @story "imageUrl" undefined}}>
      </figure>
    {{else}}
      <figure class="o--mediaContainer cPR--thumbnail__image">
        <div class="cPR--thumbnail__imageEmpty">
          {{svg-jar "star-alt" class="o--icon--lg o--icon--white"}}
        </div>
      </figure>
    {{/if}}
    
    <div class="cPR--thumbnail__count">
      <span>{{@story.reachCount}}</span>
      <span>{{t "media_kit.post.reach" locale="en-us"}}</span>
    </div>
  </div>
  
  */
  {
    "id": "woC9KJ1d",
    "block": "[[[10,0],[14,0,\"cPR--thumbnail qa--mediaKit_clientPage_igFeedPerformance_pic\"],[12],[1,\"\\n\"],[41,[30,1,[\"imageUrl\"]],[[[1,\"    \"],[10,\"figure\"],[14,0,\"o--mediaContainer cPR--thumbnail__image\"],[12],[1,\"\\n      \"],[10,\"img\"],[15,\"src\",[30,1,[\"imageUrl\"]]],[14,0,\"o--media\"],[15,\"onerror\",[28,[37,1],[[30,1],\"imageUrl\",[27]],null]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"figure\"],[14,0,\"o--mediaContainer cPR--thumbnail__image\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"cPR--thumbnail__imageEmpty\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"star-alt\"],[[\"class\"],[\"o--icon--lg o--icon--white\"]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"  \\n  \"],[10,0],[14,0,\"cPR--thumbnail__count\"],[12],[1,\"\\n    \"],[10,1],[12],[1,[30,1,[\"reachCount\"]]],[13],[1,\"\\n    \"],[10,1],[12],[1,[28,[35,3],[\"media_kit.post.reach\"],[[\"locale\"],[\"en-us\"]]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@story\"],false,[\"if\",\"set\",\"svg-jar\",\"t\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/ui/media-kit/story-card-item.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});