define("ember-svg-jar/inlined/igtv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0)\"><path d=\"M19.13 3.38h-2.46L18 2.1a1.229 1.229 0 00-.87-2.096 1.24 1.24 0 00-.87.356L14 2.57 11.79.36a1.23 1.23 0 10-1.74 1.74l1.29 1.28H8.87a7.19 7.19 0 00-7.18 7.18v10.26A7.18 7.18 0 008.87 28h10.26a7.18 7.18 0 007.18-7.18V10.56a7.19 7.19 0 00-7.18-7.18zm1.25 13.2l-6 1.61c-.11.014-.22.014-.33 0a1.24 1.24 0 01-1.23-1.25v-1.19L8.28 17a1.23 1.23 0 01-.66-2.37L13.67 13a1.199 1.199 0 011.07.2 1.23 1.23 0 01.49 1v1.26l4.49-1.25a1.231 1.231 0 01.66 2.37z\"/></g><defs><clipPath id=\"clip0\"><path fill=\"#fff\" d=\"M0 0h28v28H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});