define("ember-svg-jar/inlined/gif", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2.852 2.675c4.29-.548 8.64-.762 13.072-.642l6.166.322c.658.07 1.67.177 3.056.32.805.1 1.498.464 2.036 1.07.54.607.818 1.338.818 2.148v15.084c0 .74-.232 1.42-.685 2.006-.46.598-1.08.99-1.82 1.159A50.876 50.876 0 0114 25.474c-3.821 0-7.653-.444-11.49-1.331a3.131 3.131 0 01-1.825-1.16A3.225 3.225 0 010 20.977V5.893c0-.81.278-1.541.818-2.148a3.193 3.193 0 012.034-1.07zm12.993 1.356a82.743 82.743 0 00-12.743.629c-.325.04-.574.171-.79.414-.214.24-.312.499-.312.819v15.084c0 .297.086.547.269.785.175.227.39.364.687.431A48.877 48.877 0 0014 23.474c3.668 0 7.349-.426 11.047-1.282.293-.066.51-.203.684-.43.183-.238.269-.488.269-.785V5.893c0-.32-.098-.578-.313-.82a1.184 1.184 0 00-.768-.41c-1.367-.142-2.38-.248-2.987-.315zM8.415 9.01a4.023 4.023 0 011.74.382 4.038 4.038 0 011.185.863l-.96 1.08a3.408 3.408 0 00-.818-.585c-.295-.15-.672-.225-1.132-.225-.44 0-.837.082-1.192.248-.356.165-.66.4-.916.704a3.261 3.261 0 00-.592 1.118c-.14.44-.21.935-.21 1.485 0 1.12.252 1.997.757 2.632.506.636 1.253.953 2.243.953.28 0 .545-.037.795-.113.25-.075.455-.182.615-.322v-2.115H8.07V13.69h3.42v4.32c-.34.33-.785.607-1.335.832-.55.226-1.165.338-1.845.338a4.92 4.92 0 01-1.815-.33 4.012 4.012 0 01-1.455-.975 4.51 4.51 0 01-.96-1.59c-.23-.63-.345-1.35-.345-2.16 0-.8.12-1.517.36-2.152.24-.636.57-1.173.99-1.613.42-.44.915-.775 1.485-1.005a4.884 4.884 0 011.845-.345zm6.99.18V19h-1.74V9.19h1.74zm8.415 0v1.47h-4.185v2.775h3.57v1.47h-3.57V19h-1.74V9.19h5.925z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});