define("ember-svg-jar/inlined/GOALS", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21.661 24.063c-.758.875-1.193 1.312-2.064 1.312s-1.307-.438-2.064-1.313c-.628-.704-1.42-1.618-2.727-2.003v-6.747h4.79a2.603 2.603 0 001.987-.918l2.613-3.063a2.632 2.632 0 000-3.417l-2.613-3.062a2.615 2.615 0 00-1.986-.915h-4.79V0h-2.614v3.938H5.662c-.5 0-.988.143-1.409.414a2.636 2.636 0 00-.577 3.917l1.159 1.356-1.154 1.356a2.63 2.63 0 00.574 3.915c.42.271.908.416 1.407.416h6.533v6.747c-1.307.385-2.104 1.312-2.727 2.017-.757.861-1.193 1.299-2.064 1.299-.87 0-1.306-.438-2.064-1.313s-1.916-2.187-4.033-2.187H0V24.5h1.306c.871 0 1.307.438 2.065 1.313C4.128 26.688 5.287 28 7.403 28c2.117 0 3.223-1.269 4.029-2.188.805-.918 1.17-1.312 2.064-1.312.892 0 1.306.438 2.064 1.313S17.476 28 19.592 28c2.117 0 3.223-1.269 4.033-2.188.81-.918 1.198-1.312 2.069-1.312H27v-2.625h-1.306c-2.117 0-3.223 1.269-4.033 2.188zm-16-17.5h13.936l2.613 3.062-2.613 3.063H5.66l2.613-3.063-2.613-3.063z\" fill=\"#C6E023\"/><path d=\"M5.661 6.563h13.936l2.613 3.062-2.613 3.063H5.66l2.613-3.063-2.613-3.063z\" fill=\"#fff\" fill-opacity=\".5\"/>",
    "attrs": {
      "width": "27",
      "height": "28",
      "viewBox": "0 0 27 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});