define("ember-svg-jar/inlined/social-tiktok", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M22.024 5.586A7.071 7.071 0 0119.206 0h-4.572v12.492l-.005 6.843c0 1.83-1.191 3.38-2.843 3.926-.48.158-.997.233-1.536.204a4.121 4.121 0 01-1.893-.58 4.138 4.138 0 01-2.022-3.488 4.141 4.141 0 014.147-4.198c.456 0 .894.074 1.304.21v-4.642a9.007 9.007 0 00-1.318-.098 8.767 8.767 0 00-6.588 2.946 8.576 8.576 0 00-2.163 5.174 8.565 8.565 0 002.551 6.673 8.767 8.767 0 006.2 2.538c.445 0 .885-.033 1.318-.097a8.738 8.738 0 004.881-2.441 8.559 8.559 0 002.568-6.091l-.023-10.219a11.558 11.558 0 007.089 2.409V7.01a7.057 7.057 0 01-4.277-1.424z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});