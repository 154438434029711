define("ember-svg-jar/inlined/lib-twitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.513 3.5a5.316 5.316 0 00-5.311 5.31c0 .177.044.349.061.521-3.621-.346-6.824-2.09-9-4.76a.492.492 0 00-.81.062 5.269 5.269 0 00-.72 2.663c0 1.176.421 2.253 1.072 3.137-.126-.055-.264-.086-.383-.153a.488.488 0 00-.72.429v.06c0 1.805.934 3.372 2.312 4.332-.015-.002-.03.004-.046 0a.49.49 0 00-.551.627c.547 1.701 1.944 3 3.673 3.475a9.15 9.15 0 01-4.698 1.3c-.373 0-.731-.018-1.087-.06a.489.489 0 00-.321.902 14.124 14.124 0 007.652 2.25c4.57 0 8.155-1.91 10.56-4.637 2.405-2.728 3.658-6.271 3.658-9.581 0-.14-.011-.275-.015-.413.897-.687 1.699-1.492 2.326-2.434a.489.489 0 00-.612-.719c-.253.113-.547.124-.811.214.348-.465.646-.966.826-1.53a.49.49 0 00-.72-.566 9.177 9.177 0 01-2.739 1.056C21.16 4.097 19.911 3.5 18.513 3.5zm0 .98c1.249 0 2.378.533 3.168 1.377a.493.493 0 00.459.138 10.078 10.078 0 001.837-.551 4.36 4.36 0 01-1.256 1.148.49.49 0 00.322.918c.49-.06.926-.258 1.393-.383a9.3 9.3 0 01-1.378 1.225c-.136.1-.21.26-.199.428.008.2.016.396.016.597 0 3.061-1.175 6.384-3.413 8.923-2.239 2.538-5.525 4.316-9.826 4.316-1.953 0-3.798-.433-5.464-1.194 2.066-.159 3.972-.89 5.51-2.097a.489.489 0 00-.291-.872c-1.615-.029-2.967-.968-3.688-2.296h.076a5.32 5.32 0 001.408-.184.491.491 0 00-.03-.949c-1.743-.351-3.05-1.771-3.352-3.535.493.17.998.304 1.546.321a.49.49 0 00.29-.902 4.336 4.336 0 01-1.928-3.612c0-.559.145-1.072.337-1.562 2.448 2.688 5.877 4.473 9.749 4.668a.49.49 0 00.505-.597 4.372 4.372 0 01-.123-.994 4.322 4.322 0 014.332-4.331z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});