define("ember-svg-jar/inlined/social-instagram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M8.106 0H14c1.66 0 2.707.044 3.474.076C18.52.12 19.044.14 19.894 0c1.475 0 2.457.245 3.44.49.982.492 1.719.982 2.456 1.72l.007.007c.733.735 1.466 1.47 1.71 2.45.248.982.493 1.964.493 3.437v11.79c0 1.475-.245 2.457-.492 3.44-.49.982-.983 1.719-1.718 2.454-.737.737-1.474 1.475-2.457 1.72l-.018.004c-.976.244-1.956.488-3.421.488H8.106c-1.465 0-2.445-.244-3.42-.488l-.02-.004c-.982-.49-1.719-.983-2.456-1.72-.735-.735-1.473-1.472-1.718-2.455C.245 22.351 0 21.37 0 19.894V8.104C0 6.63.245 5.649.492 4.667c.49-.983.983-1.72 1.718-2.457C2.947 1.472 3.684.737 4.667.49 5.649.245 6.63 0 8.106 0zm11.543 2.702c1.475 0 2.21.245 2.702.49.737.245 1.227.492 1.72.982.49.493.737.983.982 1.72.245.492.49 1.227.49 2.702v11.298c0 1.475-.245 2.21-.49 2.702-.245.737-.492 1.227-.982 1.72-.493.49-.983.737-1.72.982-.492.245-1.227.49-2.702.49H8.351c-1.475 0-2.21-.245-2.702-.49-.737-.245-1.227-.492-1.72-.982-.49-.493-.737-.983-.982-1.72-.245-.492-.49-1.227-.49-2.702V8.596c0-1.475.245-2.21.49-2.702.245-.737.492-1.227.982-1.72.493-.49.983-.737 1.72-.982.492-.245 1.227-.49 2.702-.49h11.298zm.245 3.93c0-.983.737-1.72 1.72-1.72.982 0 1.72.737 1.72 1.72 0 .982-.738 1.719-1.72 1.719-.983 0-1.72-.737-1.72-1.72zM14 7.121a7.13 7.13 0 00-7.124 7.123A7.13 7.13 0 0014 21.369a7.13 7.13 0 007.124-7.124A7.13 7.13 0 0014 7.121zm0 2.456c2.702 0 4.667 2.21 4.667 4.667 0 2.702-2.21 4.667-4.667 4.667s-4.667-2.21-4.667-4.667c0-2.702 1.965-4.667 4.667-4.667z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});