define("ember-svg-jar/inlined/insights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M15 24a3 3 0 01-3-3V3a3 3 0 016 0v18a3 3 0 01-3 3zm1-22h-2v20h2V2zm7 22a3 3 0 01-3-3V10a3 3 0 016 0v11a3 3 0 01-3 3zm1-15h-2v13h2V9zM7 24a3 3 0 01-3-3v-4a3 3 0 016 0v4a3 3 0 01-3 3zM1.167 0c.644 0 1.166.522 1.166 1.167v24.5h24.5a1.167 1.167 0 110 2.333H1.167A1.167 1.167 0 010 26.833V1.167C0 .522.522 0 1.167 0zM8 16v6H6v-6h2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});