define("ember-svg-jar/inlined/brand-logo-diamond", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\".25\" y=\".575\" width=\"13\" height=\"13\" rx=\"6.5\" fill=\"#CAF1FF\"/><path d=\"M7.122 12.011a.19.19 0 01-.19-.186v-.043C6.919 8.09 4.909 7.762 4.282 7.75c-.05.003-.1.004-.151.004a.189.189 0 01-.019-.377s.05-.004.137-.004h.024c2.59-.158 2.66-4.75 2.66-4.796a.19.19 0 01.19-.188c.104 0 .187.084.187.189 0 3.957 1.788 4.202 2.157 4.211h.05a.189.189 0 01.013.377h-.06c-2.12.06-2.16 4.42-2.16 4.613v.042a.19.19 0 01-.187.189v.001z\" fill=\"#fff\" stroke=\"#000\" stroke-width=\".6\"/><path d=\"M7.122 2.58c0 4.18 2.008 4.393 2.347 4.4-2.27.059-2.346 4.334-2.347 4.802-.016-3.914-2.245-4.21-2.845-4.22C7.083 7.4 7.122 2.58 7.122 2.58zm2.394 4.398H9.47h.047zm-5.268.585h.03a2.804 2.804 0 01-.148.004s.043-.006.118-.006m2.874 4.22V11.78zm0-9.581a.378.378 0 00-.378.375c0 .056-.072 4.455-2.478 4.609h-.02c-.09 0-.145.005-.157.006a.378.378 0 00.04.754c.051 0 .103 0 .153-.003.59.012 2.445.335 2.46 3.84v.046a.378.378 0 00.378.373h.003a.378.378 0 00.375-.378v-.042c0-.192.04-4.365 1.973-4.425h.013l.056-.001a.378.378 0 00.354-.377c0-.2-.168-.378-.378-.378H9.47c-.139-.004-.59-.054-1.026-.515C7.826 5.43 7.5 4.218 7.5 2.578a.379.379 0 00-.377-.378h-.001z\" fill=\"#000\"/>",
    "attrs": {
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});