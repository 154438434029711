define("@latermedia/ember-later-analytics/utils/constants/placeholder-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module Utils
   */

  /**
   * @class PlaceholderUrlUtil
   * @extends Utils
   *
   * Placeholder URL for any media within analytics
   *
   * @property PLACEHOLDER_URL
   * @returns {String}
   */
  const PLACEHOLDER_URL = '/assets/images/img--empty__igPost.png';
  var _default = _exports.default = PLACEHOLDER_URL;
});