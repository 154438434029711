define("ember-svg-jar/inlined/deactivate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M15.166 2.333v7.072l-2.333 2.333V2.333c0-.322.114-.597.342-.825.228-.228.503-.342.825-.342.322 0 .597.114.825.342.228.228.341.503.341.825zm-9.77 16.47c.038.087.076.173.115.257L3.78 20.792c-.193-.35-.368-.713-.525-1.087a11.502 11.502 0 01-.921-4.538c0-1.58.307-3.093.92-4.54a11.536 11.536 0 012.498-3.709c.225-.225.498-.337.82-.337.323 0 .6.112.83.337.23.225.346.498.347.82 0 .323-.116.6-.347.83a9.391 9.391 0 00-2.005 2.962 9.366 9.366 0 00-.73 3.637c0 1.258.244 2.47.73 3.637zm4.066 7.11l-.002-.002 1.813-1.813a9.344 9.344 0 002.728.403c1.258 0 2.47-.244 3.637-.73a9.391 9.391 0 002.962-2.005 9.393 9.393 0 002.005-2.962 9.368 9.368 0 00.73-3.637c0-.934-.135-1.844-.403-2.728l1.814-1.813v.002c.614 1.446.921 2.959.921 4.538 0 1.58-.307 3.093-.92 4.54a11.537 11.537 0 01-2.498 3.709 11.554 11.554 0 01-3.71 2.497c-1.446.614-2.959.921-4.539.921-1.58 0-3.092-.307-4.538-.92zm17.971-21.91a1.527 1.527 0 10-2.16-2.16L1.335 25.784a1.527 1.527 0 002.16 2.16l23.938-23.94z\"/>",
    "attrs": {
      "width": "28",
      "height": "29",
      "viewBox": "0 0 28 29",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});