define("ember-svg-jar/inlined/emoji-smile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14 0c7.72 0 14 6.28 14 14s-6.28 14-14 14S0 21.72 0 14 6.28 0 14 0zm0 2C7.372 2 2 7.372 2 14s5.372 12 12 12 12-5.372 12-12S20.628 2 14 2zm6.916 15.938a1 1 0 01-.088 1.411A10.308 10.308 0 0114 21.932c-2.517 0-4.942-.918-6.828-2.583a1 1 0 011.324-1.499A8.31 8.31 0 0014 19.932a8.31 8.31 0 005.504-2.082 1 1 0 011.412.088zM18.8 9.6a2 2 0 110 4 2 2 0 010-4zm-9.6 0a2 2 0 110 4 2 2 0 010-4z\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});