define("ember-svg-jar/inlined/ig-reels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 8.73V20a8 8 0 008 8h12a8 8 0 008-8V8.73zm18.18 10.19L12 22.51a1.13 1.13 0 01-1.67-1v-7.19a1.12 1.12 0 011.67-1l6.16 3.58a1.19 1.19 0 01.02 2.02zM27.8 6.23A8 8 0 0020 0h-2.14l3.72 6.23zM5.46.42A8 8 0 00.2 6.23h8.72zM18.66 6.23L14.95 0H8.12l3.72 6.23h6.82z\"/>",
    "attrs": {
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 28 28"
    }
  };
});