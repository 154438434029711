define("ember-svg-jar/inlined/tag-product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M6 1.583a1.25 1.25 0 011-.5h14c.393 0 .764.185 1 .5l3.5 4.667a1.264 1.264 0 01.25.715v16.368a3.583 3.583 0 01-3.583 3.583H5.833a3.583 3.583 0 01-3.583-3.583V7a1.241 1.241 0 01.25-.75L6 1.583zm14.375 2L22 5.75H6l1.625-2.167h12.75zM4.75 23.333V8.25h18.5v15.083a1.083 1.083 0 01-1.083 1.083H5.833a1.083 1.083 0 01-1.083-1.083zm5.833-11.666a1.25 1.25 0 00-2.5 0 5.917 5.917 0 0011.834 0 1.25 1.25 0 10-2.5 0 3.417 3.417 0 01-6.834 0z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});