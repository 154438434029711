define("ember-svg-jar/inlined/tone-professional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.77 13.53a8.571 8.571 0 10-16.04 0c-.43-.058-1.266.213-1.266.646v.043A9.973 9.973 0 01.75 10.5c0-5.523 4.477-10 10-10s10 4.477 10 10c0 1.314-.253 2.57-.714 3.719v-.043c0-.433-.837-.704-1.266-.645z\"/><path d=\"M7.893 7.643a1.429 1.429 0 11-2.857 0 1.429 1.429 0 012.857 0zM16.464 7.643a1.429 1.429 0 11-2.857 0 1.429 1.429 0 012.857 0zM9.169 10.73c-.28-.272-.791-.322-1.068-.046a.717.717 0 00.002 1.013l.066.06c.035.032.083.07.142.116.12.09.29.202.511.313a4.278 4.278 0 001.927.432c.838 0 1.482-.21 1.926-.432a3.348 3.348 0 00.654-.428l.068-.063a.714.714 0 000-1.01c-.277-.277-.788-.227-1.068.045-.059.044-.156.11-.292.178-.27.135-.697.282-1.288.282-.59 0-1.018-.147-1.288-.282a1.925 1.925 0 01-.292-.178zM2.893 15.5v4.067c0 .435.385.769.815.707l3.572-.51a.714.714 0 00.613-.707v-1.166H6.107a.357.357 0 110-.714h1.786V16.01a.714.714 0 00-.613-.707l-3.572-.51a.714.714 0 00-.815.707zM13.607 17.89h1.786a.357.357 0 000-.713h-1.786V16.01c0-.355.261-.657.613-.707l3.572-.51a.714.714 0 01.815.707v4.067a.714.714 0 01-.815.707l-3.572-.51a.714.714 0 01-.613-.707v-1.166zM8.607 16.82c0-.395.32-.715.714-.715h2.858c.394 0 .714.32.714.714v1.429c0 .394-.32.714-.714.714H9.32a.714.714 0 01-.714-.714v-1.429z\"/>",
    "attrs": {
      "width": "21",
      "height": "21",
      "viewBox": "0 0 21 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});