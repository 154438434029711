define("ember-svg-jar/inlined/skittle_pinterest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_276_3342)\"><ellipse cx=\"12\" cy=\"11.733\" rx=\"12\" ry=\"11.733\" fill=\"#fff\"/><path d=\"M12 0C5.373 0 0 5.267 0 11.765c0 4.986 3.16 9.247 7.625 10.96-.109-.929-.198-2.362.04-3.379.216-.92 1.402-5.848 1.402-5.848s-.356-.707-.356-1.743c0-1.636.968-2.856 2.173-2.856 1.027 0 1.52.755 1.52 1.655 0 1.007-.65 2.518-.997 3.922-.286 1.171.603 2.13 1.778 2.13 2.133 0 3.773-2.208 3.773-5.384 0-2.817-2.064-4.783-5.017-4.783-3.418 0-5.422 2.508-5.422 5.103 0 1.007.395 2.091.888 2.682a.345.345 0 01.08.34c-.09.367-.297 1.17-.336 1.335-.05.213-.178.262-.405.155-1.502-.687-2.44-2.827-2.44-4.56 0-3.709 2.746-7.117 7.931-7.117 4.158 0 7.398 2.905 7.398 6.797 0 4.057-2.608 7.32-6.223 7.32-1.215 0-2.36-.62-2.745-1.355l-.75 2.798c-.268 1.026-.998 2.305-1.492 3.089 1.126.339 2.31.523 3.555.523 6.627 0 12-5.268 12-11.765C24 5.267 18.627 0 12 0z\" fill=\"#E60023\"/></g><defs><clipPath id=\"clip0_276_3342\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});