define("ember-svg-jar/inlined/learn-trend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 8.409v12.677A1.913 1.913 0 001.917 23h19.166A1.918 1.918 0 0023 21.086V1.919A1.886 1.886 0 0020.908.011a1.891 1.891 0 00-.975.4l-5.09 3.985L9.093 1.2a1.919 1.919 0 00-2.204.24L.644 6.98A1.915 1.915 0 000 8.41zM16.1 6.33l4.6-3.572v5.13l-4.6 3.572V6.33zM9.2 3.891l4.6 2.554v5.414L9.2 9.305V3.89zm-6.9 4.69L6.9 4.5v4.977l-4.6 4.074V8.58zm0 8.04l5.903-5.241 6.85 3.802 5.647-4.384v9.905H2.3V16.62z\" fill=\"#30CD52\"/><path d=\"M2.3 16.621l5.903-5.241 6.85 3.802 5.647-4.384v9.905H2.3V16.62z\" fill=\"#fff\" fill-opacity=\".5\"/>",
    "attrs": {
      "width": "23",
      "height": "23",
      "viewBox": "0 0 23 23",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});