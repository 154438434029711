define("ember-svg-jar/inlined/crop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-opacity=\".873\" fill-rule=\"evenodd\" d=\"M26.81 20.5c.657 0 1.19.53 1.19 1.191v.118c0 .658-.535 1.191-1.198 1.191H23v3.802c0 .662-.53 1.198-1.191 1.198h-.118a1.194 1.194 0 01-1.191-1.198V23H6.995A2 2 0 015 21.005V7.5H1.198C.536 7.5 0 6.97 0 6.309V6.19C0 5.533.535 5 1.198 5H5V1.198C5 .536 5.53 0 6.191 0h.118C6.967 0 7.5.527 7.5 1.19V20.5h19.31zM21.69 19c-.658 0-1.191-.54-1.191-1.209V7.5H10.209C9.541 7.5 9 6.97 9 6.309V6.19A1.19 1.19 0 0110.194 5h10.8C22.102 5 23 5.897 23 7.006v10.8c0 .66-.53 1.194-1.191 1.194h-.118z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});