define("@latermedia/ember-later-analytics/components/ui/reel-card-item", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="o--cardContainer tRE--cardContainer"
    data-test-id="reel-card-item"
  >
    <BestPerformingMedia::Item
      @media={{@reel}}
      @isLoading={{@isLoading}}
      @handleError={{@handleError}}
      @isReport={{@isReport}}
    >
      <BestPerformingMedia::Number
          @isLoading={{@isLoading}}
          @showLoadingState={{true}}
          @text={{t "best_posts.reach"}}
          @number={{@reel.reachCount}}
          @isReport={{@isReport}}
        />
    </BestPerformingMedia::Item>
  </div>
  */
  {
    "id": "DM9UdZ+5",
    "block": "[[[10,0],[14,0,\"o--cardContainer tRE--cardContainer\"],[14,\"data-test-id\",\"reel-card-item\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@media\",\"@isLoading\",\"@handleError\",\"@isReport\"],[[30,1],[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@isLoading\",\"@showLoadingState\",\"@text\",\"@number\",\"@isReport\"],[[30,2],true,[28,[37,2],[\"best_posts.reach\"],null],[30,1,[\"reachCount\"]],[30,4]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@reel\",\"@isLoading\",\"@handleError\",\"@isReport\"],false,[\"best-performing-media/item\",\"best-performing-media/number\",\"t\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/ui/reel-card-item.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});