define("@latermedia/ember-later-analytics/utils/demographics", ["exports", "@ember/utils", "@ember/array", "@latermedia/ember-later-analytics/utils/world-data", "@latermedia/ember-later-analytics/utils"], function (_exports, _utils, _array, _worldData, _utils2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module Utils
   */

  /**
   * @class audience
   * @extends Utils
   */

  /**
   * Finds the Country name for a provided country code.
   * Makes use of the countryList util.
   *
   * @method _getCountryNameFromCode
   * @param {String} code
   * @protected
   *
   * @returns {String} name of the corresponding country if it exists.
   * Otherwise it falls back to the code provided
   */
  const _getCountryNameFromCode = code => {
    const country = (0, _array.A)(_worldData.countryList).findBy('code', code);
    return country ? country.name : code;
  };

  /**
   * Takes a raw countries object and converts
   * it to be consumed by the report UI
   *
   * @method formatCountries
   * @param {Object} countries
   *
   * @returns {Array.<Object>} formatted array of country values
   */
  const formatCountries = countries => {
    if (typeof countries !== 'object' || (0, _utils.isEmpty)(countries) || (0, _utils.isEmpty)(Object.entries(countries))) {
      return [];
    }
    return (0, _array.A)(Object.entries(countries).map(_ref => {
      let [countryCode, decimalAsStr] = _ref;
      return {
        countryCode,
        title: _getCountryNameFromCode(countryCode.toUpperCase()),
        value: decimalAsStr ? Number(decimalAsStr) : null,
        percentageOfWidth: decimalAsStr ? (0, _utils2.preciseRound)(Number(decimalAsStr), 2) : null
      };
    })).sortBy('value').reverse();
  };
  var _default = _exports.default = formatCountries;
});