define("ember-svg-jar/inlined/lib-btn-round-side", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M5 14a9 9 0 019-9h8a1 1 0 110 2h-8a7 7 0 100 14h8a1 1 0 110 2h-8a9 9 0 01-9-9z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});