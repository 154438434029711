define("ember-svg-jar/inlined/skittle_image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"12\" cy=\"12\" r=\"12\" fill=\"#1178B3\"/><g clip-path=\"url(#clip0_276_3348)\" fill=\"#fff\"><path d=\"M15 11.167c.71 0 1.286-.56 1.286-1.25S15.71 8.667 15 8.667c-.71 0-1.286.56-1.286 1.25s.576 1.25 1.286 1.25z\"/><path d=\"M17.25 7H6.75c-.418 0-.75.33-.75.737v8.526c0 .406.332.737.75.737h10.5c.418 0 .75-.33.75-.737V7.737A.742.742 0 0017.25 7zm-2.762 5.148a.465.465 0 00-.342-.161c-.137 0-.234.063-.343.148l-.501.412c-.105.073-.188.122-.308.122a.45.45 0 01-.295-.107 3.39 3.39 0 01-.115-.106l-1.441-1.516a.598.598 0 00-.448-.195.616.616 0 00-.45.203l-3.388 3.974V8.138a.35.35 0 01.351-.305h9.581c.185 0 .335.133.346.313l.008 6.781-2.655-2.779z\"/></g><defs><clipPath id=\"clip0_276_3348\"><path fill=\"#fff\" transform=\"translate(6 7)\" d=\"M0 0h12v10H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});