define("ember-svg-jar/inlined/product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.852 3.344c.3-.52.964-.699 1.484-.399L19.75 9.527a1.087 1.087 0 11-1.087 1.883L7.25 4.828c-.52-.3-.698-.964-.398-1.484z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.69.484a3.624 3.624 0 013.62 0l.002.001 8.876 5.073A3.623 3.623 0 0126 8.692V18.839a3.624 3.624 0 01-1.812 3.134l-.004.003-8.872 5.07-.003.001a3.623 3.623 0 01-3.618 0l-.003-.001-8.872-5.07-.004-.003A3.623 3.623 0 011 18.84V8.692a3.623 3.623 0 011.812-3.134l.004-.003 8.872-5.07h.003zm1.81 1.69a1.45 1.45 0 00-.725.194l-.004.002L3.9 7.44l-.002.002a1.45 1.45 0 00-.723 1.252v10.143a1.45 1.45 0 00.723 1.252l.002.002 8.876 5.072a1.448 1.448 0 001.45 0l.004-.003 8.872-5.07h.002a1.449 1.449 0 00.723-1.253V8.694a1.45 1.45 0 00-.723-1.252l-.002-.001-8.876-5.073a1.45 1.45 0 00-.725-.194z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.488 6.83c.301-.52.966-.697 1.486-.397l10.526 6.09 10.526-6.09a1.087 1.087 0 111.089 1.882l-11.07 6.404a1.087 1.087 0 01-1.09 0L1.886 8.315c-.52-.3-.697-.965-.397-1.485z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.5 12.679c.6 0 1.087.486 1.087 1.086v12.783a1.087 1.087 0 01-2.174 0V13.766c0-.6.487-1.087 1.087-1.087z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});