define("ember-svg-jar/inlined/skittle_ig_post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"12\" cy=\"12\" r=\"12\" fill=\"#F00075\"/><path d=\"M12 5c-1.901 0-2.14.008-2.886.042-.745.034-1.254.152-1.7.325-.46.18-.85.419-1.24.808-.388.389-.628.78-.807 1.24-.173.445-.29.954-.325 1.699C5.008 9.86 5 10.099 5 12c0 1.901.008 2.14.042 2.886.034.745.152 1.254.325 1.7.18.46.419.85.808 1.24.39.388.78.628 1.24.806.445.173.954.292 1.699.326.747.034.985.042 2.886.042 1.901 0 2.14-.008 2.886-.042.745-.034 1.254-.152 1.7-.325a3.43 3.43 0 001.24-.808c.388-.389.628-.78.806-1.24.174-.445.292-.954.326-1.699.034-.746.042-.985.042-2.886 0-1.901-.008-2.14-.042-2.886-.034-.745-.152-1.254-.325-1.7a3.43 3.43 0 00-.808-1.24 3.43 3.43 0 00-1.24-.807c-.445-.173-.954-.29-1.699-.325C14.14 5.008 13.901 5 12 5zm0 1.261c1.87 0 2.09.007 2.829.041.682.031 1.053.145 1.3.241.326.127.56.279.804.524.245.245.397.478.524.805.096.246.21.617.24 1.3.035.738.042.959.042 2.828 0 1.87-.007 2.09-.041 2.829-.031.682-.145 1.053-.241 1.3a2.17 2.17 0 01-.524.804 2.17 2.17 0 01-.805.524c-.246.096-.617.21-1.3.24-.737.035-.959.042-2.828.042-1.87 0-2.09-.007-2.829-.041-.682-.031-1.053-.145-1.3-.241a2.17 2.17 0 01-.804-.524 2.17 2.17 0 01-.524-.805c-.096-.246-.21-.617-.24-1.3-.035-.737-.042-.959-.042-2.828 0-1.87.007-2.09.041-2.829.031-.682.145-1.053.241-1.3.127-.326.279-.56.524-.804.245-.245.478-.397.805-.524.246-.096.617-.21 1.3-.24.738-.035.959-.042 2.828-.042z\" fill=\"#fff\"/><path d=\"M12 14.334a2.333 2.333 0 110-4.667 2.333 2.333 0 010 4.667zm0-5.928a3.595 3.595 0 100 7.189 3.595 3.595 0 000-7.19zM16.576 8.263a.84.84 0 11-1.68 0 .84.84 0 011.68 0z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});