define("ember-svg-jar/inlined/messenger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.576.016C11.034.2 9.81.501 8.484 1.024 5.167 2.334 2.47 4.85 1.074 7.94a12.124 12.124 0 00.096 10.184c.786 1.655 2.064 3.299 3.43 4.41.304.248.31.255.326.42.01.092.023 1.19.031 2.438l.014 2.27.074.12a.44.44 0 00.41.219c.123 0 .497-.181 2.586-1.253l2.441-1.252.46.092c1.161.235 1.949.315 3.093.314.902 0 1.374-.032 2.17-.149 2.724-.398 5.348-1.568 7.355-3.28.35-.3 1.056-1.001 1.372-1.364 1.69-1.944 2.699-4.146 3.04-6.641.075-.546.102-1.899.05-2.507-.324-3.854-2.466-7.357-5.87-9.6-.685-.453-1.667-.98-2.272-1.222l-.429-.17a15.493 15.493 0 00-2.549-.735 10.683 10.683 0 00-1.61-.205c-.465-.03-2.487-.039-2.716-.012zm2.867 2.747c3.437.404 6.488 2.214 8.28 4.912a9.621 9.621 0 011.554 4.193c.072.574.053 1.826-.036 2.41-.554 3.627-3.083 6.641-6.796 8.099-1.468.576-3.061.834-4.83.782-1.08-.032-1.757-.128-2.916-.41a6.102 6.102 0 00-.53-.115c-.055 0-1.209.575-2.127 1.06-.188.1-.35.181-.36.181-.011 0-.02-.51-.02-1.132 0-1.01-.006-1.144-.059-1.248-.04-.08-.23-.25-.589-.525-1.197-.921-1.954-1.709-2.666-2.774A9.581 9.581 0 012.83 14.38c-.09-.529-.126-1.754-.068-2.315.233-2.267 1.232-4.349 2.871-5.983 1.958-1.952 4.584-3.13 7.513-3.37.506-.041 1.748-.014 2.297.05zM12.408 8.88c-.037.015-.249.214-.47.442-.964.99-6.167 6.58-6.482 6.964-.204.25-.182.52.06.725.167.14.336.122.731-.077.319-.16 1.789-.95 3.78-2.031.535-.29 1.175-.635 1.422-.765l.45-.237.221.232c.122.128.742.798 1.378 1.49 1.558 1.693 1.595 1.73 1.76 1.758a.668.668 0 00.248-.012c.31-.09.58-.37 4.57-4.731 2.268-2.48 2.497-2.737 2.583-2.912.164-.334-.06-.71-.424-.71-.109 0-.271.075-.855.397-.397.219-1.62.889-2.717 1.489l-2.215 1.211-.219.12-1.491-1.494a87.045 87.045 0 00-1.719-1.693c-.21-.183-.239-.198-.386-.196a.822.822 0 00-.225.03z\" fill=\"#323B43\"/>",
    "attrs": {
      "width": "29",
      "height": "29",
      "viewBox": "0 0 29 29",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});