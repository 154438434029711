define("ember-svg-jar/inlined/lib-page-import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19.104 1.032c1.797 0 3.257 1.575 3.257 3.54v3.866a9.462 9.462 0 00-2.104-.408V4.573c0-.695-.514-1.253-1.153-1.253L4.257 3.287c-.639 0-1.153.559-1.153 1.253v19.207c0 .694.514 1.253 1.153 1.253l9.438.02A9.459 9.459 0 0019.5 27c.383 0 .762-.023 1.133-.067a3.019 3.019 0 01-1.529.419L4.257 27.32C2.469 27.32 1 25.723 1 23.78V4.54C1 2.597 2.469 1 4.257 1l14.847.032z\"/><path d=\"M19.39 20.064l-2.916-2.639a.797.797 0 01-.274-.591c0-.225.1-.435.274-.592a.905.905 0 011.22 0l1.423 1.288v-4.002c0-.236.09-.451.26-.605A.917.917 0 0120 12.7a.92.92 0 01.624.223c.17.154.258.37.258.605v4.002l1.424-1.288a.905.905 0 011.22 0 .798.798 0 01.273.592c0 .224-.1.435-.274.591l-2.917 2.64A.966.966 0 0120 20.3a.969.969 0 01-.61-.236zM17 20.75a.75.75 0 000 1.5h6a.75.75 0 000-1.5h-6z\"/><path fill-rule=\"evenodd\" d=\"M28 17.6a8 8 0 11-16 0 8 8 0 0116 0zm-2 0a6 6 0 11-12 0 6 6 0 0112 0z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});