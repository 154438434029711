define("@latermedia/ember-later-analytics/utils/state-types/no-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module Utils
   */

  /**
   * @class NoDataUtil
   * @extends Utils
   */

  /**
   * No Data State
   *
   * @property NO_DATA
   * @returns {string}
   */
  const NO_DATA = 'noData';
  var _default = _exports.default = NO_DATA;
});