define("ember-svg-jar/inlined/underline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M23.33 23a1.46 1.46 0 110 2.92H4.67a1.46 1.46 0 110-2.92zM21 2.04c.806 0 1.46.654 1.46 1.46v8.17A8.47 8.47 0 0114 20.12a8.47 8.47 0 01-8.46-8.45V3.5a1.46 1.46 0 112.92 0v8.17a5.54 5.54 0 1011.08 0V3.5c0-.806.654-1.46 1.46-1.46z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});