define("ember-svg-jar/inlined/mavely-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect width=\"35\" height=\"35\" rx=\"17.5\" fill=\"#5662EF\"/><path d=\"M13.736 7.548c0 1.522-2.18 9.789-2.18 12.217 0 .636.24.907.532.907.417 0 .563-.584.845-.584.073 0 .177.052.177.334 0 .886-.761 2.106-2.18 2.106-1.084 0-1.897-.793-1.897-2.293 0-2.367 1.804-9.174 1.804-10.341 0-.459-.188-.72-.511-.823-.178-.094-.397-.188-.397-.428 0-.427.376-.677 1.179-1.021.917-.386 1.772-.573 2.116-.573.303 0 .511.156.511.5zM19.04 12.197c0 .824-1.627 4.326-1.627 6.745 0 1.73.897 1.876 1.366 1.876 1.7 0 3.494-3.085 3.494-5.42 0-.668-.167-1.095-.46-1.47-.291-.375-.594-.396-.594-.813 0-.74.73-1.47 1.69-1.47 1.115 0 1.585.959 1.585 2.543 0 2.304-.922 6.088-2.08 8.34-1.355 2.648-3.332 5.456-6.179 5.424-1.407-.016-2.575-.924-2.575-2.123 0-.803.583-1.328 1.136-1.328.271 0 .437.308.625.6.334.531.679.865 1.388.87 2.273.017 4.358-4.424 4.358-5.09 0-.053-.032-.095-.104-.095-.188 0-1.356 1.72-3.243 1.72-1.408 0-2.816-.813-2.816-3.22 0-2.013.99-4.14.99-4.858 0-.49-.27-.647-.594-.761-.282-.136-.448-.23-.448-.521 0-.407.375-.688 1.042-.96.866-.354 2.044-.541 2.42-.541.5 0 .625.292.625.552h.001zM24.982 22.683a.985.985 0 100-1.97.985.985 0 000 1.97z\" fill=\"#F9F8F7\"/>",
    "attrs": {
      "width": "35",
      "height": "35",
      "viewBox": "0 0 35 35",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});