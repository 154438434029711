define("ember-svg-jar/inlined/exit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M3.36 25.4C1.456 25.4 0 23.944 0 22.04V6.36C0 4.456 1.456 3 3.36 3H16.8c1.904 0 3.36 1.456 3.36 3.36v4.48h-2.24V6.36c0-.672-.448-1.12-1.12-1.12H3.36c-.672 0-1.12.448-1.12 1.12v15.68c0 .672.448 1.12 1.12 1.12H16.8c.672 0 1.12-.448 1.12-1.12v-4.48h2.24v4.48c0 1.904-1.456 3.36-3.36 3.36H3.36zM21.616 8.936a1.083 1.083 0 000 1.568l2.576 2.576H10.08c-.672 0-1.12.448-1.12 1.12 0 .672.448 1.12 1.12 1.12h14.112l-2.576 2.576a1.083 1.083 0 000 1.568c.448.448 1.12.448 1.568 0l4.48-4.48c.224-.224.336-.448.336-.784s-.112-.56-.336-.784l-4.48-4.48a1.083 1.083 0 00-1.568 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});