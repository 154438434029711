define("ember-svg-jar/inlined/hourglass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#323B43\" fill-rule=\"evenodd\" d=\"M1.5 2c0-.621.504-1.125 1.125-1.125h22.75a1.125 1.125 0 010 2.25H23V3.6c0 4.119-1.307 8.075-3.712 10.4C21.693 16.325 23 20.281 23 24.4v.475h2.375a1.125 1.125 0 010 2.25H2.625a1.125 1.125 0 010-2.25H5V24.4c0-4.119 1.307-8.075 3.712-10.4C6.307 11.675 5 7.719 5 3.6v-.475H2.625A1.125 1.125 0 011.5 2zm5.75 22.875h13.5V24.4c0-4.354-1.609-7.976-3.868-9.46a1.125 1.125 0 010-1.88c2.26-1.484 3.868-5.106 3.868-9.46v-.475H7.25V3.6c0 4.354 1.609 7.976 3.868 9.46a1.125 1.125 0 010 1.88c-2.26 1.484-3.868 5.106-3.868 9.46v.475z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});