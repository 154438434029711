define("ember-svg-jar/inlined/tag-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M25.292 28C26.807 28 28 26.753 28 25.274V6.654a2.722 2.722 0 00-2.708-2.727h-6.794L14.688.185A.699.699 0 0014.23 0h-.458a.655.655 0 00-.46.185l-3.81 3.742H2.709C1.193 3.927 0 5.175 0 6.653v18.62A2.722 2.722 0 002.708 28h22.584zM2.667 7.686c0-.672.541-1.218 1.207-1.218h6.452c.124 0 .29-.084.416-.21l3.289-3.36 3.413 3.36c.083.084.25.21.416.21h6.534c.666 0 1.207.504 1.207 1.176v16.738c0 .672-.541 1.218-1.207 1.218H21.5V24a5 5 0 00-5-5h-5a5 5 0 00-5 5v1.6H3.874a1.214 1.214 0 01-1.207-1.218V7.686zM9 25.6h10V24a2.5 2.5 0 00-2.5-2.5h-5A2.5 2.5 0 009 24v1.6zm5-15.35a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zM8.75 13a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});