define("@latermedia/ember-later-analytics/utils/state-types/index", ["exports", "@latermedia/ember-later-analytics/utils/state-types/backfilling-data", "@latermedia/ember-later-analytics/utils/state-types/data", "@latermedia/ember-later-analytics/utils/state-types/error", "@latermedia/ember-later-analytics/utils/state-types/loading", "@latermedia/ember-later-analytics/utils/state-types/no-boards", "@latermedia/ember-later-analytics/utils/state-types/no-data", "@latermedia/ember-later-analytics/utils/state-types/partial-data", "@latermedia/ember-later-analytics/utils/state-types/private-boards"], function (_exports, _backfillingData, _data, _error, _loading, _noBoards, _noData, _partialData, _privateBoards) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "BACKFILLING_DATA", {
    enumerable: true,
    get: function () {
      return _backfillingData.default;
    }
  });
  Object.defineProperty(_exports, "DATA", {
    enumerable: true,
    get: function () {
      return _data.default;
    }
  });
  Object.defineProperty(_exports, "ERROR", {
    enumerable: true,
    get: function () {
      return _error.default;
    }
  });
  Object.defineProperty(_exports, "LOADING", {
    enumerable: true,
    get: function () {
      return _loading.default;
    }
  });
  Object.defineProperty(_exports, "NO_BOARDS", {
    enumerable: true,
    get: function () {
      return _noBoards.default;
    }
  });
  Object.defineProperty(_exports, "NO_DATA", {
    enumerable: true,
    get: function () {
      return _noData.default;
    }
  });
  Object.defineProperty(_exports, "PARTIAL_DATA", {
    enumerable: true,
    get: function () {
      return _partialData.default;
    }
  });
  Object.defineProperty(_exports, "PRIVATE_BOARDS", {
    enumerable: true,
    get: function () {
      return _privateBoards.default;
    }
  });
});