define("@latermedia/ember-later-analytics/components/ui/media-kit/tiktok-best-performing-posts", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @bestPerformingPosts as |post|}}
    <Ui::MediaKit::TiktokPostCardItem
      @isLoading={{is-loading @state}}
      @post={{post}}
      @isReport={{@isReport}}
    />
  {{/each}}
  {{#if @dataNotAvailable}}
    <Ui::States::NotFound @className="tRE--cardOverlay"/>
  {{/if}}
  
  */
  {
    "id": "YT0zIlNm",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"  \"],[8,[39,2],null,[[\"@isLoading\",\"@post\",\"@isReport\"],[[28,[37,3],[[30,3]],null],[30,2],[30,4]]],null],[1,\"\\n\"]],[2]],null],[41,[30,5],[[[1,\"  \"],[8,[39,5],null,[[\"@className\"],[\"tRE--cardOverlay\"]],null],[1,\"\\n\"]],[]],null]],[\"@bestPerformingPosts\",\"post\",\"@state\",\"@isReport\",\"@dataNotAvailable\"],false,[\"each\",\"-track-array\",\"ui/media-kit/tiktok-post-card-item\",\"is-loading\",\"if\",\"ui/states/not-found\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/ui/media-kit/tiktok-best-performing-posts.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});