define("@latermedia/ember-later-analytics/components/ui/media-kit/story-summary", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul class="cPR--stats">
    <li class="cPR--stats__li qa--mediaKit_clientPage_igStoryPerformance_reach">
      <p>{{t "media_kit.story.avg_reach" locale="en-us"}}</p>
      <span class="cPR--stats__count">{{rounded-value @tiles.averageReach.main.value}}</span>
    </li>
    <li class="cPR--stats__li qa--mediaKit_clientPage_igStoryPerformance_impressions">
      <p>{{t "media_kit.story.avg_impressions" locale="en-us"}}</p>
      <span class="cPR--stats__count">{{rounded-value @tiles.averageImpressions.main.value}}</span>
    </li>
    <li class="cPR--stats__li qa--mediaKit_clientPage_igStoryPerformance_replies">
      <p>{{t "media_kit.story.avg_replies" locale="en-us"}}</p>
      <span class="cPR--stats__count">{{rounded-value @tiles.averageReplies.main.value}}</span>
    </li>
  </ul>
  
  */
  {
    "id": "TmrGZ6jb",
    "block": "[[[10,\"ul\"],[14,0,\"cPR--stats\"],[12],[1,\"\\n  \"],[10,\"li\"],[14,0,\"cPR--stats__li qa--mediaKit_clientPage_igStoryPerformance_reach\"],[12],[1,\"\\n    \"],[10,2],[12],[1,[28,[35,0],[\"media_kit.story.avg_reach\"],[[\"locale\"],[\"en-us\"]]]],[13],[1,\"\\n    \"],[10,1],[14,0,\"cPR--stats__count\"],[12],[1,[28,[35,1],[[30,1,[\"averageReach\",\"main\",\"value\"]]],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"cPR--stats__li qa--mediaKit_clientPage_igStoryPerformance_impressions\"],[12],[1,\"\\n    \"],[10,2],[12],[1,[28,[35,0],[\"media_kit.story.avg_impressions\"],[[\"locale\"],[\"en-us\"]]]],[13],[1,\"\\n    \"],[10,1],[14,0,\"cPR--stats__count\"],[12],[1,[28,[35,1],[[30,1,[\"averageImpressions\",\"main\",\"value\"]]],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"cPR--stats__li qa--mediaKit_clientPage_igStoryPerformance_replies\"],[12],[1,\"\\n    \"],[10,2],[12],[1,[28,[35,0],[\"media_kit.story.avg_replies\"],[[\"locale\"],[\"en-us\"]]]],[13],[1,\"\\n    \"],[10,1],[14,0,\"cPR--stats__count\"],[12],[1,[28,[35,1],[[30,1,[\"averageReplies\",\"main\",\"value\"]]],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@tiles\"],false,[\"t\",\"rounded-value\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/ui/media-kit/story-summary.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});