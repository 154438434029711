define("ember-svg-jar/inlined/promo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M25.164 2.836a1.258 1.258 0 010 1.78L4.615 25.163a1.258 1.258 0 11-1.779-1.78L23.385 2.837a1.258 1.258 0 011.779 0zM22.073 19.661a2.411 2.411 0 100 4.823 2.411 2.411 0 000-4.823zm-4.928 2.412a4.927 4.927 0 119.855 0 4.927 4.927 0 01-9.855 0zM5.927 3.516a2.411 2.411 0 100 4.823 2.411 2.411 0 000-4.823zM1 5.927a4.927 4.927 0 119.855 0 4.927 4.927 0 01-9.855 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});