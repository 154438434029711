define("ember-svg-jar/inlined/chrome-extension", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#a)\"><path fill-rule=\"evenodd\" d=\"M10.5.5c-1.657 0-3 1.254-3 2.8l1.263 2.358A2 2 0 019 6.602V7.5h3v-.898a2 2 0 01.237-.944L13.5 3.3c0-1.546-1.343-2.8-3-2.8zm11.843 17.737L24.7 19.5c1.546 0 2.8-1.343 2.8-3s-1.254-3-2.8-3l-2.357 1.263a2 2 0 01-.945.237H20.5v3h.898a2 2 0 01.945.237zM2.5 7.5a2 2 0 00-2 2v5.497a2 2 0 00.842-.234L3.7 13.5v6l-2.358-1.263a2 2 0 00-.842-.234V25.5a2 2 0 002 2h9.497a2 2 0 00-.234-.843L10.5 24.3c0-1.546 1.343-2.8 3-2.8s3 1.254 3 2.8l-1.263 2.357a2 2 0 00-.234.843H18.5a2 2 0 002-2v-16a2 2 0 00-2-2h-16zm4 9c0 1.657-1.254 3-2.8 3v-6c1.546 0 2.8 1.343 2.8 3z\" clip-rule=\"evenodd\"/></g><defs><clipPath id=\"a\"><path d=\"M0 0h28v28H0z\"/></clipPath></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});