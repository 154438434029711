define("@latermedia/ember-later-analytics/utils/format-number-with-suffix", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module Utils
   */

  /**
   * @class FormatNumberWithSuffixUtil
   * @extends Utils
   */

  /**
   * Converts count to use metric suffix symbols.
   *
   * If you are getting an error in here, you might
   * be passing in a string representation of a number.
   *
   * Using unary plus here to convert to number
   * see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Arithmetic_Operators#Unary_plus_()
   *
   * @method formatNumberWithSuffix
   * @param {Number} count Number to be formatted
   * @param {Number} [roundTo=0] Number of decimal places to round to for a count between 10,000-1,000,000
   *
   * @return {String} Converted value that uses metric symbol presentation
   */
  const formatNumberWithSuffix = function (number) {
    let roundTo = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    if ((0, _utils.isNone)(number)) {
      return '';
    } else if (isNaN(number)) {
      return `${number}`;
    } else if (number < 1) {
      return `${+number.toFixed(2)}`;
    } else if (number < 10) {
      return `${+number.toFixed(1)}`;
    } else if (number < 100) {
      return `${+number.toFixed(0)}`;
    } else if (number < 1000) {
      return `${+number.toFixed(0)}`;
    } else if (number < 10000) {
      const smaller = number / 1000;
      return `${+smaller.toFixed(1) + 'K'}`;
    } else if (number < 1000000) {
      const smaller = number / 1000;
      return `${+smaller.toFixed(roundTo) + 'K'}`;
    } else if (number < 100000000) {
      const smaller = number / 1000000;
      return `${+smaller.toFixed(1) + 'M'}`;
    }
    // i.e. if number < 1000000000
    const smaller = number / 1000000;
    return `${+smaller.toFixed(0) + 'M'}`;
  };
  var _default = _exports.default = formatNumberWithSuffix;
});