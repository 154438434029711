define("ember-svg-jar/inlined/share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M20.4 16.07c-1.38 0-2.645.575-3.45 1.61l-5.865-2.875c.115-.345.115-.69.115-.92 0-.23 0-.69-.115-.92l5.865-2.875c.805.92 2.07 1.61 3.45 1.61 2.53 0 4.6-2.07 4.6-4.6s-2.07-4.6-4.6-4.6-4.6 2.07-4.6 4.6c0 .345 0 .69.115.92l-5.865 2.875c-.805-.92-2.07-1.61-3.45-1.61-2.53 0-4.6 2.07-4.6 4.6s2.07 4.6 4.6 4.6c1.38 0 2.645-.575 3.45-1.61l5.865 2.875c-.115.345-.115.69-.115.92 0 2.53 2.07 4.6 4.6 4.6s4.6-2.07 4.6-4.6-2.07-4.6-4.6-4.6zm0-11.5c1.265 0 2.3 1.035 2.3 2.3s-1.035 2.3-2.3 2.3a2.307 2.307 0 01-2.3-2.3c0-1.265 1.035-2.3 2.3-2.3zM6.6 16.07a2.307 2.307 0 01-2.3-2.3c0-1.265 1.035-2.3 2.3-2.3s2.3 1.035 2.3 2.3-1.035 2.3-2.3 2.3zm13.8 6.9a2.307 2.307 0 01-2.3-2.3c0-1.265 1.035-2.3 2.3-2.3s2.3 1.035 2.3 2.3-1.035 2.3-2.3 2.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});