define("ember-svg-jar/inlined/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M7.003 19.167c.55 0 .997.453.997.997v6.006c0 .55-.453.997-.997.997H.997c-.55 0-.997-.453-.997-.997v-6.006c0-.55.453-.997.997-.997h6.006zM5.667 21.5H2.333v3.333h3.334V21.5zm21 .333c.8 0 1.333.534 1.333 1.334s-.533 1.333-1.333 1.333H12c-.8 0-1.333-.533-1.333-1.333 0-.8.533-1.334 1.333-1.334zm-19.664-12c.55 0 .997.453.997.997v6.006c0 .55-.453.997-.997.997H.997c-.55 0-.997-.453-.997-.997V10.83c0-.55.453-.997.997-.997h6.006zm-1.336 2.334H2.333V15.5h3.334v-3.333zm21 .333c.8 0 1.333.533 1.333 1.333 0 .8-.533 1.334-1.333 1.334H12c-.8 0-1.333-.534-1.333-1.334S11.2 12.5 12 12.5zM7.003.5c.55 0 .997.453.997.997v6.006c0 .55-.453.997-.997.997H.997C.447 8.5 0 8.047 0 7.503V1.497C0 .947.453.5.997.5h6.006zM5.667 2.833H2.333v3.334h3.334V2.833zm21 .334c.8 0 1.333.533 1.333 1.333 0 .8-.533 1.333-1.333 1.333H12c-.8 0-1.333-.533-1.333-1.333 0-.8.533-1.333 1.333-1.333z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});