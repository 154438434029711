define("ember-svg-jar/inlined/mavely-emoji", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.166.478c0 1.456-1.93 9.365-1.93 11.689 0 .608.212.867.47.867.37 0 .499-.558.748-.558.065 0 .157.05.157.32 0 .847-.674 2.014-1.93 2.014-.96 0-1.681-.758-1.681-2.194 0-2.264 1.598-8.777 1.598-9.894 0-.438-.166-.688-.453-.787-.157-.09-.35-.18-.35-.41 0-.408.332-.648 1.043-.977C2.65.18 3.408 0 3.713 0c.268 0 .453.15.453.479zM8.864 4.926c0 .788-1.44 4.14-1.44 6.453 0 1.655.793 1.795 1.21 1.795 1.505 0 3.093-2.952 3.093-5.186 0-.638-.148-1.047-.406-1.406-.259-.359-.527-.379-.527-.778 0-.708.647-1.406 1.497-1.406.988 0 1.404.917 1.404 2.433 0 2.204-.816 5.825-1.842 7.979-1.2 2.533-2.951 5.22-5.473 5.19-1.247-.015-2.282-.884-2.282-2.031 0-.768.517-1.27 1.007-1.27.24 0 .387.293.553.573.296.508.602.828 1.23.833 2.013.015 3.86-4.233 3.86-4.871 0-.05-.028-.09-.092-.09-.167 0-1.201 1.646-2.873 1.646-1.247 0-2.494-.778-2.494-3.082 0-1.925.877-3.96.877-4.648 0-.469-.24-.618-.526-.728-.25-.13-.397-.22-.397-.498 0-.39.332-.659.923-.918.767-.339 1.81-.519 2.143-.519.443 0 .554.28.554.529h.001zM14.127 14.959c.482 0 .873-.422.873-.942s-.39-.943-.873-.943c-.482 0-.872.422-.872.943 0 .52.39.942.872.942z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "15",
      "height": "20",
      "viewBox": "0 0 15 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});