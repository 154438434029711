define("ember-svg-jar/inlined/capital-a", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M22.31 20.31l-7-14a1.53 1.53 0 00-2.62 0l-7 14a1.465 1.465 0 002.62 1.31l2.22-4.44h6.94l2.22 4.44c.25.494.756.806 1.31.81.226 0 .45-.055.65-.16a1.45 1.45 0 00.66-1.96zM12 14.26l2-4 2 4h-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});