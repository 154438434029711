define("ember-svg-jar/inlined/lib-featured-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M25.71 13.477l-11.2-11.2a2.473 2.473 0 00-1.755-.721h-8.71a2.496 2.496 0 00-2.49 2.489v8.71c0 .685.274 1.307.735 1.768l11.2 11.2c.448.448 1.07.722 1.754.722.685 0 1.307-.274 1.755-.735L25.71 17c.46-.449.734-1.071.734-1.755 0-.685-.286-1.32-.734-1.768zM15.244 23.968l-11.2-11.212V4.045h8.712v-.013l11.2 11.2-8.712 8.736z\"/><path d=\"M7.156 9.022a1.867 1.867 0 100-3.733 1.867 1.867 0 000 3.733z\"/><path fill-rule=\"evenodd\" d=\"M13.222 15.111a1.889 1.889 0 100-3.778 1.889 1.889 0 000 3.778zm0 2a3.889 3.889 0 100-7.778 3.889 3.889 0 000 7.778zm1.626 3.959a1 1 0 010-1.413l4.809-4.808a1 1 0 111.414 1.414l-4.808 4.808a1 1 0 01-1.415 0z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});