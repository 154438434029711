define("ember-svg-jar/inlined/dropbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M8.327 1.125L.22 6.374l5.606 4.45L14 5.82zM.22 15.276l8.107 5.248L14 15.829l-8.174-5.004zM14 15.829l5.673 4.695 8.107-5.248-5.606-4.451zM27.78 6.374l-8.107-5.249L14 5.82l8.174 5.004z\"/><path d=\"M14.017 17l-5.69 4.681-2.435-1.576v1.606l8.125 4.83 8.124-4.83v-1.606l-2.435 1.576z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});