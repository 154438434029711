define("ember-svg-jar/inlined/italic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M22.17 3.21h-10.5a1.46 1.46 0 100 2.91h3.73L9.49 21.88H5.83a1.46 1.46 0 000 2.91h10.5a1.46 1.46 0 100-2.91H12.6l5.91-15.76h3.66a1.46 1.46 0 000-2.91z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});