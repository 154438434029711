define("ember-svg-jar/inlined/ig-reels-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20 0H8a8 8 0 00-8 8v12a8 8 0 008 8h12a8 8 0 008-8V8a8 8 0 00-8-8zm5.2 6.23h-4.84L18.14 2.5H20a5.5 5.5 0 015.2 3.73zm-13 0L9.92 2.5h5.3l2.23 3.73zM7.06 2.59l2.18 3.64H2.8a5.49 5.49 0 014.26-3.64zM20 25.5H8A5.51 5.51 0 012.5 20V8.73h23V20a5.51 5.51 0 01-5.5 5.5zm-8-13.29l6.16 3.58a1.19 1.19 0 010 2L12 21.41a1.13 1.13 0 01-1.67-1v-7.19A1.12 1.12 0 0112 12.21z\"/>",
    "attrs": {
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 28 28"
    }
  };
});