define("ember-code-snippet/-private/get-snippet", ["exports", "ember-code-snippet/snippets", "ember-code-snippet/-private/language", "ember-code-snippet/-private/extension", "ember-code-snippet/-private/unindent", "@ember/debug"], function (_exports, _snippets, _language, _extension, _unindent, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getSnippet;
  function getSnippet(name) {
    let unindent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    let source = name.split('/').reduce((dir, name) => dir && dir[name], _snippets.default);
    (false && !(source) && (0, _debug.assert)(`Code snippet with name "${name}" not found.`, source));
    source = source.replace(/^(\s*\n)*/, '').replace(/\s*$/, '');
    if (unindent) {
      source = (0, _unindent.default)(source);
    }
    let language = (0, _language.default)(name);
    let extension = (0, _extension.default)(name);
    return {
      source,
      language,
      extension
    };
  }
});