define("ember-svg-jar/inlined/brand-find-creator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.185 7.222c.396 0 .742-.24.88-.612l.437-1.182 1.182-.438a.93.93 0 00.612-.879.929.929 0 00-.612-.879l-1.182-.438-.438-1.182A.929.929 0 005.185 1a.929.929 0 00-.879.612l-.438 1.182-1.182.438a.93.93 0 00-.612.88c0 .395.24.74.612.878l1.182.438.438 1.182a.929.929 0 00.88.612zm2.636 7.511a2.281 2.281 0 01-1.347 1.348L2.73 17.467a.663.663 0 00-.449.644c0 .302.167.54.448.644h.002l3.743 1.387A2.282 2.282 0 017.82 21.49l1.386 3.744a.661.661 0 00.645.448.66.66 0 00.643-.447l.94-2.536a8.33 8.33 0 001.662 2.08l-.462 1.246a2.941 2.941 0 01-2.783 1.939 2.942 2.942 0 01-2.784-1.939L5.68 22.282 1.94 20.895A2.941 2.941 0 010 18.111c0-1.254.76-2.348 1.938-2.784l3.744-1.386 1.386-3.743A2.941 2.941 0 019.852 8.26c1.256 0 2.348.76 2.784 1.94l.752 2.03a8.34 8.34 0 00-1.716 1.937l-1.175-3.175a.662.662 0 00-.645-.45.66.66 0 00-.643.447l-.001.003-1.387 3.742zM18.9 6.728a2.281 2.281 0 001.348-1.347l.494-1.335.494 1.335a2.281 2.281 0 001.347 1.347l1.335.494-1.335.495a2.281 2.281 0 00-1.347 1.347l-.494 1.334-.495-1.334A2.282 2.282 0 0018.9 7.717l-1.335-.495 1.335-.494zm-.792-2.14l.876-2.363A1.858 1.858 0 0120.74 1c.793 0 1.483.48 1.758 1.225l.876 2.364 2.363.875a1.858 1.858 0 011.225 1.758c0 .793-.48 1.483-1.224 1.759l-2.365.875-.582 1.574a8.258 8.258 0 00-4.505-1.088l-.18-.486-2.364-.875a1.858 1.858 0 01-1.225-1.759c0-.792.48-1.483 1.225-1.758l2.364-.875zm4.165 19.113a6.222 6.222 0 111.467-1.467l3.957 3.959a1.037 1.037 0 01-1.466 1.466l-3.958-3.957zm.2-5.071a3.806 3.806 0 11-7.611 0 3.806 3.806 0 017.612 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});