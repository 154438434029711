define("ember-svg-jar/inlined/gift-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.5 17h3.9a1.3 1.3 0 000-2.6H7.5a1.3 1.3 0 000 2.6zM23.1 4H4.9A3.9 3.9 0 001 7.9v11.7a3.9 3.9 0 003.9 3.9h18.2a3.9 3.9 0 003.9-3.9V7.9A3.9 3.9 0 0023.1 4zm1.3 15.6a1.3 1.3 0 01-1.3 1.3H4.9a1.3 1.3 0 01-1.3-1.3v-7.8h20.8v7.8zm0-10.4H3.6V7.9a1.3 1.3 0 011.3-1.3h18.2a1.3 1.3 0 011.3 1.3v1.3z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});