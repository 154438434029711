define("@latermedia/ember-later-analytics/models/state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * @module Models
   */

  /**
   * Defines an analytics state model.
   * Note: At any given time, each UI component within analytics must be in a single analytics state.
   * Throughout the lifecycle of that UI component it should transition between analytics states (E.g. loading -> data)
   * All valid state model types are defined inside 'analytics/utils/state-types.js'
   *
   *
   * @class State
   */
  class State {
    /**
     * Constructs an instance of the state model class
     *
     * @method constructor
     * @param {String} type See 'analytics/utils/state-types.js' for a full list of state types
     * @param {String|undefined|null} [text=null]
     * @param {AnalyticsError|undefined|null} [metadata=null]
     * @param {StateModel|undefined|null} [substate=null]
     *
     * @returns StateModel
     */
    constructor(type) {
      let text = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let metadata = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      let substate = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      _defineProperty(this, "type", void 0);
      _defineProperty(this, "text", void 0);
      _defineProperty(this, "metadata", void 0);
      _defineProperty(this, "substate", void 0);
      this.type = type;
      this.text = text;
      this.metadata = metadata;
      this.substate = substate;
    }
  }
  _exports.default = State;
});