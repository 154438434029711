define("ember-svg-jar/inlined/notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M23.848 16.406v-4.208c0-4.197-2.482-7.802-6.203-9.26C17.272 1.258 15.794 0 14.033 0c-1.762 0-3.24 1.258-3.613 2.938-3.72 1.458-6.203 5.064-6.203 9.26v4.208l-2.212 2.27c-.196.202-.305.471-.305.752v3.206c0 .576.467 1.043 1.043 1.043H9.7C9.705 25.98 11.728 28 14.033 28s4.327-2.02 4.332-4.323h6.957c.576 0 1.043-.468 1.043-1.043v-3.206c0-.28-.11-.55-.305-.751l-2.212-2.271zm-9.815 9.508c-1.233 0-2.241-1.162-2.247-2.238h4.493c-.006 1.076-1.014 2.238-2.246 2.238zm10.246-4.323H3.786v-1.753l2.517-2.584v-5.056c0-3.802 2.517-6.926 6.112-7.687v-.74c0-.899.719-1.685 1.618-1.685.898 0 1.617.786 1.617 1.685v.74c3.596.76 6.113 3.885 6.113 7.687v5.056l2.516 2.584v1.753z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});