define("ember-svg-jar/inlined/high-performing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.126 1.5a1.125 1.125 0 10-2.25 0v3.571a1.125 1.125 0 002.25 0V1.5z\"/><path fill-rule=\"evenodd\" d=\"M16.01 8.782a1.125 1.125 0 00-1.981-.86L5.1 18.638a1.125 1.125 0 00.865 1.845h6.761l-.735 5.878a1.125 1.125 0 001.98.86l8.93-10.714a1.125 1.125 0 00-.865-1.845h-6.761l.734-5.879zM14 18.232H8.367l4.934-5.921-.417 3.335A1.125 1.125 0 0014 16.911h5.634L14.7 22.832l.417-3.335A1.124 1.124 0 0014 18.232z\" clip-rule=\"evenodd\"/><path d=\"M6.892 3.32a1.126 1.126 0 011.591 0l2.527 2.528a1.125 1.125 0 11-1.591 1.59L6.892 4.913a1.125 1.125 0 010-1.591zm-1.82 5.983a1.125 1.125 0 000 2.25h3.571a1.125 1.125 0 000-2.25H5.072zm13.16 1.125c0-.62.504-1.125 1.125-1.125h3.572a1.125 1.125 0 010 2.25h-3.572a1.125 1.125 0 01-1.125-1.125zm2.876-5.516a1.125 1.125 0 00-1.591-1.591L16.99 5.848a1.125 1.125 0 001.59 1.59l2.528-2.526z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});