define("ember-svg-jar/inlined/archived", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M24.833 8.5v17.333c0 1.197-.97 2.167-2.166 2.167H5.333a2.167 2.167 0 01-2.166-2.167V8.5A2.167 2.167 0 011 6.333V4.167C1 2.97 1.97 2 3.167 2h21.666C26.03 2 27 2.97 27 4.167v2.166C27 7.53 26.03 8.5 24.833 8.5zm-19.5 17.333h17.334V8.5H5.333v17.333zm19.5-21.666H3.167v2.166h21.666V4.167zm-8.666 13h-4.334a3.25 3.25 0 010-6.5h4.334a3.25 3.25 0 110 6.5zm0-4.334h-4.334a1.083 1.083 0 000 2.167h4.334a1.083 1.083 0 100-2.167z\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});