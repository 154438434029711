define("ember-svg-jar/inlined/approved", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#a)\"><path d=\"M12.77 15.977l-2.107-2.426c-.45-.462-1.126-.462-1.575 0a1.138 1.138 0 000 1.618l2.895 3.234c.45.463 1.125.463 1.575 0l5.355-6.44c.45-.461.45-1.154 0-1.616-.45-.463-1.238-.463-1.576 0l-4.566 5.63z\"/><path fill-rule=\"evenodd\" d=\"M17.32 1.742c-1.558-2.323-5.082-2.323-6.64 0a1.244 1.244 0 01-1.463.459C6.54 1.205 3.689 3.203 3.844 5.966a1.181 1.181 0 01-.904 1.2C.168 7.878-.92 11.111.888 13.258a1.144 1.144 0 010 1.484c-1.809 2.147-.72 5.38 2.052 6.092.558.143.935.644.904 1.2-.155 2.763 2.696 4.761 5.373 3.765a1.245 1.245 0 011.463.459c1.558 2.323 5.082 2.323 6.64 0a1.243 1.243 0 011.463-.459c2.677.996 5.528-1.002 5.373-3.765a1.181 1.181 0 01.904-1.2c2.772-.712 3.861-3.945 2.052-6.092a1.144 1.144 0 010-1.484c1.81-2.147.72-5.38-2.052-6.092a1.181 1.181 0 01-.904-1.2c.155-2.763-2.696-4.761-5.373-3.765-.538.2-1.149.009-1.462-.459zm-4.344 1.434c.48-.716 1.567-.716 2.048 0 1.017 1.516 2.997 2.137 4.744 1.487.825-.307 1.704.309 1.656 1.16-.1 1.804 1.123 3.429 2.932 3.893.855.22 1.19 1.216.633 1.878a3.711 3.711 0 000 4.812c.557.662.222 1.658-.633 1.878-1.81.465-3.033 2.089-2.932 3.892.048.852-.831 1.468-1.656 1.161-1.747-.65-3.727-.03-4.744 1.487-.48.716-1.567.716-2.048 0-1.017-1.516-2.997-2.137-4.744-1.487-.825.307-1.704-.309-1.656-1.16.101-1.804-1.123-3.428-2.932-3.893-.854-.22-1.19-1.216-.633-1.878a3.71 3.71 0 000-4.812c-.557-.662-.221-1.658.633-1.878 1.81-.464 3.033-2.089 2.932-3.892-.048-.852.831-1.468 1.656-1.161 1.747.65 3.727.03 4.744-1.487z\" clip-rule=\"evenodd\"/></g><defs><clipPath id=\"a\"><path d=\"M0 0h28v28H0z\"/></clipPath></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});