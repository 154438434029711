define("@latermedia/ember-later-analytics/components/ui/audience-section", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cCH--chartGrid__item" data-test-id="audience-section">
    {{#if @subtitle}}
      <p class="u--text--bold u--m__b__xs {{@titleQAClass}}">{{@title}}</p>
      <p class="cCH--chartGrid__title u--m__b__md {{@subtitleQAClass}}">{{@subtitle}}</p>
    {{else}}
      <h5 class="cCH--chartGrid__title {{@titleQAClass}}">
        {{@title}}
        <GlossaryLink @glossaryLink="#audienceDemographics--glossary" />
      </h5>
    {{/if}}
  
    <div class="cCH--chartGrid__container {{if @isLoading "is--loading"}}">
      {{yield}}
    </div>
    {{#if @wrapperComponent}}
      {{@wrapperComponent}}
    {{/if}}
  </div>
  
  */
  {
    "id": "emUd+T+H",
    "block": "[[[10,0],[14,0,\"cCH--chartGrid__item\"],[14,\"data-test-id\",\"audience-section\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[10,2],[15,0,[29,[\"u--text--bold u--m__b__xs \",[30,2]]]],[12],[1,[30,3]],[13],[1,\"\\n    \"],[10,2],[15,0,[29,[\"cCH--chartGrid__title u--m__b__md \",[30,4]]]],[12],[1,[30,1]],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"h5\"],[15,0,[29,[\"cCH--chartGrid__title \",[30,2]]]],[12],[1,\"\\n      \"],[1,[30,3]],[1,\"\\n      \"],[8,[39,1],null,[[\"@glossaryLink\"],[\"#audienceDemographics--glossary\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n  \"],[10,0],[15,0,[29,[\"cCH--chartGrid__container \",[52,[30,5],\"is--loading\"]]]],[12],[1,\"\\n    \"],[18,7,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,6],[[[1,\"    \"],[1,[30,6]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@subtitle\",\"@titleQAClass\",\"@title\",\"@subtitleQAClass\",\"@isLoading\",\"@wrapperComponent\",\"&default\"],false,[\"if\",\"glossary-link\",\"yield\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/ui/audience-section.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});