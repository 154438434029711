define("@latermedia/ember-later-analytics/utils/world-data/gender-list", ["exports", "@ember/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module Utils
   */

  /**
   * @class GenderListUtil
   * @extends Utils
   *
   * Lists all supported Genders and their gender codes.
   *
   * @property genderList
   * @returns {Array<WorldData>}
   */
  const genderList = (0, _array.A)([{
    name: 'Male',
    code: 'M'
  }, {
    name: 'Female',
    code: 'F'
  }, {
    name: 'Prefer Not To Say',
    code: 'U'
  }]);
  var _default = _exports.default = genderList;
});