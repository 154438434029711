define("@latermedia/ember-later-analytics/utils/dashed-to-camel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module Utils
   */
  /**
   * @class dashedToCamel
   * @extends Utils
   */
  /** Returns a camel cased version of the given dasherized string
   *
   * @method dashedToCamel
   * @param {String} str dasherized string
   *
   * @returns {String} Camel cased version of str
   * @protected
   */
  var _default = str => {
    if (!str.includes('-')) {
      return str;
    }
    return str.toLowerCase().replace(/-([a-z])/g, char => char.toUpperCase().replace('-', ''));
  };
  _exports.default = _default;
});