define("ember-svg-jar/inlined/lib-btn-shadow-none", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4 5h16v16H4V5z\"/><path fill-rule=\"evenodd\" d=\"M21 8v1h1v1h1V8h-2zm2 6h-1v4h1v-4zm0 8h-1v1h-1v1h2v-2zm-6 2v-1h-4v1h4zm-8 0v-1H8v-1H7v2h2z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});