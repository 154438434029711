define("@latermedia/ember-later-analytics/utils/convert-number-to-meta", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module Utils
   */

  /**
   * @class ConvertNumberToMetaUtil
   * @extends Utils
   */

  /**
   * Converts a numerical value to a metadata object.
   *
   * @method convertNumberToMeta
   * @param {Number} [value=null] Numerical value
   * @param {Boolean} [isPercent=false] Boolean to indicate if value is a percentage
   * @param {String} [tooltip=null] Translation string for tooltip
   * @param {Boolean} [isLoading=false] Boolean to indicate whether the stat is loading
   *
   * @return {Object} Metadata object including value, isPercent, and isIncreasing
   */
  const convertNumberToMeta = function () {
    let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    let isPercent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    let tooltip = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    let isLoading = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    let isBackfilling = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
    if ((0, _utils.isPresent)(value) && typeof value === 'string') {
      const strAsNum = Number.parseInt(value);
      return {
        value: Math.abs(strAsNum),
        isIncreasing: strAsNum >= 0,
        isPercent,
        tooltip,
        isLoading,
        isBackfilling
      };
    }
    if ((0, _utils.isPresent)(value) && typeof value === 'number') {
      return {
        value: Math.abs(value),
        isIncreasing: value >= 0,
        isPercent,
        tooltip,
        isLoading,
        isBackfilling
      };
    }
    return {
      value: null,
      isIncreasing: false,
      isPercent,
      tooltip,
      isLoading,
      isBackfilling
    };
  };
  var _default = _exports.default = convertNumberToMeta;
});