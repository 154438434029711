define("ember-svg-jar/inlined/align-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M19.83 19.54a1.46 1.46 0 110 2.92H3.5a1.46 1.46 0 110-2.92zm4.67-4.67a1.46 1.46 0 110 2.92h-21a1.46 1.46 0 110-2.92zm-4.67-4.66a1.46 1.46 0 110 2.91H3.5a1.46 1.46 0 110-2.91zm4.67-4.67a1.46 1.46 0 110 2.92h-21a1.46 1.46 0 110-2.92z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});