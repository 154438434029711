define("ember-code-snippet/-private/language", ["exports", "ember-code-snippet/-private/extension"], function (_exports, _extension) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getLanguage;
  function getLanguage(name) {
    let ext = (0, _extension.default)(name);
    if (ext) {
      switch (ext) {
        case 'js':
          return 'javascript';
        case 'coffee':
          return 'coffeescript';
        case 'hbs':
          return 'handlebars';
        case 'css':
          return 'css';
        case 'scss':
          return 'scss';
        case 'less':
          return 'less';
        case 'emblem':
          return 'emblem';
        case 'ts':
          return 'typescript';
        default:
          return ext;
      }
    }
  }
});