define("ember-svg-jar/inlined/nav-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M26.324 20.825c-.65.65-1.625.65-2.275 0L13.812 10.588 3.574 20.825c-.65.65-1.625.65-2.275 0-.65-.65-.65-1.625 0-2.274L12.674 7.175c.65-.65 1.625-.65 2.275 0L26.324 18.55c.325.325.488.813.488 1.138 0 .325-.163.813-.488 1.137z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});