define("ember-svg-jar/inlined/phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.91 28c-.51 0-.955-.19-1.337-.573C5.19 27.046 5 26.6 5 26.091V1.909c0-.509.19-.954.573-1.336C5.955.19 6.4 0 6.909 0h14c.51 0 .955.19 1.337.573.381.382.572.827.572 1.336v24.182c0 .509-.19.954-.573 1.336-.381.382-.827.573-1.336.573h-14zm0-4.773h14V4.773h-14v18.454zm0 1.91v.954h14v-.955h-14zm0-22.273h14v-.955h-14v.955zm0-.955v.955-.955zm0 24.182v-.955.955z\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});