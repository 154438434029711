define("ember-svg-jar/inlined/media-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M26.863 9.454c0 .71-.473 1.182-1.182 1.182S24.5 10.163 24.5 9.454V5.2l-5.448 5.448a1.142 1.142 0 01-1.654 0 1.142 1.142 0 010-1.655l5.448-5.448H18.59c-.709 0-1.182-.473-1.182-1.182s.473-1.181 1.182-1.181h7.091c.71 0 1.182.472 1.182 1.181v7.091zM9.273 6.727c-2.01 0-3.546 1.537-3.546 3.546s1.537 3.545 3.546 3.545 3.545-1.536 3.545-3.545c0-2.01-1.536-3.546-3.545-3.546zm0 4.728c-.71 0-1.182-.473-1.182-1.182 0-.71.473-1.182 1.182-1.182s1.182.473 1.182 1.182-.473 1.182-1.182 1.182zM26.758 21.241l-1.767 1.768-5.882-5.882-9.49 9.492L7.85 24.85 19.11 13.593l7.648 7.648z\"/><path d=\"M25.818 14c-.709 0-1.182.473-1.182 1.182v8.273c0 .709-.472 1.181-1.181 1.181H4.545c-.709 0-1.181-.472-1.181-1.181V4.545c0-.709.472-1.181 1.181-1.181h8.273c.71 0 1.182-.473 1.182-1.182C14 1.472 13.527 1 12.818 1H4.545C2.536 1 1 2.536 1 4.545v18.91C1 25.464 2.536 27 4.545 27h18.91C25.464 27 27 25.464 27 23.455v-8.273c0-.71-.473-1.182-1.182-1.182z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});