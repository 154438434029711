define("ember-svg-jar/inlined/overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M20 7A6 6 0 118 7a6 6 0 0112 0zm-2.33 0a3.67 3.67 0 11-7.34 0 3.67 3.67 0 017.34 0z\" clip-rule=\"evenodd\"/><path d=\"M25 25.835a1.165 1.165 0 01-2.33 0V22A4.67 4.67 0 0018 17.33h-8A4.67 4.67 0 005.33 22v3.835a1.165 1.165 0 01-2.33 0V22a7 7 0 017-7h8a7 7 0 017 7v3.835z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});