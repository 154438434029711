define("ember-svg-jar/inlined/apple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M25.461 20.455a17.564 17.564 0 01-2.063 4.244C21.924 26.88 20.509 28 19.036 28c-.53 0-1.297-.177-2.358-.59-.943-.353-1.769-.53-2.535-.53-.648 0-1.474.177-2.358.59-.943.353-1.65.53-2.24.53-1.71 0-3.419-1.415-5.07-4.362C2.826 20.749 2 17.92 2 15.208c0-2.593.648-4.656 1.886-6.307 1.238-1.65 2.889-2.417 4.775-2.417.825 0 1.827.177 3.006.472 1.18.353 1.946.53 2.3.53.53 0 1.296-.177 2.416-.59 1.18-.353 2.122-.589 2.948-.589 1.296 0 2.534.413 3.536 1.12.59.413 1.18.944 1.769 1.71-.884.707-1.533 1.415-1.887 1.945a5.825 5.825 0 00-1.12 3.478c0 1.415.413 2.653 1.18 3.773.766 1.12 1.65 1.827 2.652 2.122zM19.095.707c0 .708-.118 1.474-.472 2.3a7.137 7.137 0 01-1.532 2.298c-.649.649-1.238 1.002-1.828 1.238a9.05 9.05 0 01-1.768.295c.059-1.71.471-3.124 1.297-4.362.825-1.18 2.24-2.004 4.244-2.476v.177c.059.118.059.177.059.177v.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});