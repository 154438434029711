define("ember-svg-jar/inlined/preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.6 9A1.4 1.4 0 017 10.4v4.1a16.248 16.248 0 012-1.909V10.4A1.4 1.4 0 0110.4 9h4.2a1.4 1.4 0 011.343 1.002L16 10c.703 0 1.376.07 2.017.197A1.395 1.395 0 0119.4 9h4.2a1.4 1.4 0 011.4 1.4l.001 4.101C27.001 16.752 28 19 28 19s-4 9-12 9c-4 0-7-2.25-9-4.5v.1A1.4 1.4 0 015.6 25H1.4A1.4 1.4 0 010 23.6v-4.2A1.4 1.4 0 011.4 18l3.115.002c.3-.536.731-1.244 1.294-2.019A1.232 1.232 0 015.6 16H1.4A1.4 1.4 0 010 14.6v-4.2A1.4 1.4 0 011.4 9h4.2zM16 12c-5.3 0-8.6 5-9.7 7 1.1 2 4.5 7 9.7 7 5.3 0 8.6-5 9.7-7a18.116 18.116 0 00-2.075-3.001L23.6 16l-1.75-1.75C20.306 12.982 18.35 12 16 12zm0 2c2.75 0 5 2.25 5 5s-2.25 5-5 5-5-2.25-5-5 2.25-5 5-5zM4.515 20H2v3h3v-2.183c-.188-.301-.35-.577-.485-.818zM16 16c-1.65 0-3 1.35-3 3s1.35 3 3 3 3-1.35 3-3-1.35-3-3-3zM5 11H2v3h3v-3zm18 0h-2.541c.934.435 1.782.985 2.542 1.592L23 11zm.6-11A1.4 1.4 0 0125 1.4v4.2A1.4 1.4 0 0123.6 7h-4.2A1.4 1.4 0 0118 5.6V1.4A1.4 1.4 0 0119.4 0h4.2zm-9 0A1.4 1.4 0 0116 1.4v4.2A1.4 1.4 0 0114.6 7h-4.2A1.4 1.4 0 019 5.6V1.4A1.4 1.4 0 0110.4 0h4.2zm-9 0A1.4 1.4 0 017 1.4v4.2A1.4 1.4 0 015.6 7H1.4A1.4 1.4 0 010 5.6V1.4A1.4 1.4 0 011.4 0h4.2zM23 2h-3v3h3V2zm-9 0h-3v3h3V2zM5 2H2v3h3V2z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});