define("ember-svg-jar/inlined/lib-facebook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.662 1.75c-1.742 0-3.246.531-4.303 1.58-1.056 1.05-1.63 2.587-1.63 4.474v2.468H7.532a.533.533 0 00-.533.532v4.261a.533.533 0 00.533.533h3.195v10.12a.533.533 0 00.533.532h4.26a.532.532 0 00.533-.533v-10.12h3.729a.533.533 0 00.528-.465l.533-4.261a.532.532 0 00-.529-.6h-4.26v-2.13c0-.594.47-1.065 1.065-1.065h3.195a.533.533 0 00.533-.533V2.467a.533.533 0 00-.463-.528c-.47-.062-2.17-.189-3.723-.189zm0 1.065c1.298 0 2.553.104 3.12.157v3.039H17.12c-1.17 0-2.13.96-2.13 2.13v2.663a.533.533 0 00.532.533h4.19l-.4 3.196h-3.79a.533.533 0 00-.533.532v10.12h-3.196v-10.12a.533.533 0 00-.532-.532H8.065v-3.196h3.196a.533.533 0 00.533-.533v-3c0-1.675.49-2.897 1.316-3.719.827-.821 2.023-1.27 3.552-1.27z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});