define("ember-svg-jar/inlined/draft-post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M7.479 25.742a1 1 0 00.706-.293L24.723 8.91c1.437-1.717 1.317-4.125-.096-5.538-1.413-1.413-3.821-1.533-5.538-.096L2.55 19.814a1 1 0 00-.293.707l-.002 4.222a1 1 0 001 1l4.223-.001zM17.558 1.619L.298 18.879a1.002 1.002 0 00-.294.708L0 26.997C0 27.551.449 28 1.003 28l7.41-.004c.265 0 .52-.105.708-.293l17.26-17.26c2.205-2.553 2.163-6.342-.16-8.665-2.322-2.322-6.111-2.364-8.663-.16z\" clip-rule=\"evenodd\"/><path d=\"M14.25 26.875c0-.621.504-1.125 1.125-1.125h1.25a1.125 1.125 0 010 2.25h-1.25a1.125 1.125 0 01-1.125-1.125zm5 0c0-.621.504-1.125 1.125-1.125h1.25a1.125 1.125 0 010 2.25h-1.25a1.125 1.125 0 01-1.125-1.125zm5 0c0-.621.504-1.125 1.125-1.125h1.25a1.125 1.125 0 010 2.25h-1.25a1.125 1.125 0 01-1.125-1.125z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});