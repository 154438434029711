define("ember-svg-jar/inlined/creator-manage-campaign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M19 2.165a1.165 1.165 0 012.33 0V8.93A9.912 9.912 0 0019 8.795V6.394L2.33 9.402v7.196l7.442 1.343a10 10 0 00.122 2.39l-1.564-.283V23.5a1.17 1.17 0 102.34 0v-.704c.45.962 1.05 1.84 1.771 2.602A3.5 3.5 0 016 23.5v-3.872l-3.67-.663v.87a1.165 1.165 0 11-2.33 0V6.165a1.165 1.165 0 112.33 0v.87L19 4.025v-1.86zm8.583 10.69a1.165 1.165 0 00-.748-2.058h-1.258a9.91 9.91 0 012.006 2.059zm-.102-6.923a1.165 1.165 0 10-1.292-1.938l-3 2a1.165 1.165 0 101.292 1.938l3-2zM19.6 12.511a6.118 6.118 0 100 12.237 6.118 6.118 0 000-12.237zm-8.4 6.119a8.4 8.4 0 1116.8 0 8.4 8.4 0 01-16.8 0zm6.74 4.133c.287.115.594.198.919.25v.209a.74.74 0 001.481 0v-.189a3.633 3.633 0 001.091-.307c.492-.24.865-.56 1.12-.957.255-.398.382-.83.382-1.293 0-.54-.136-.974-.409-1.306a2.603 2.603 0 00-.974-.746 11.151 11.151 0 00-1.463-.497 14.196 14.196 0 01-1.027-.323c-.238-.1-.44-.224-.607-.373a.792.792 0 01-.237-.584c0-.332.106-.585.316-.759.22-.174.51-.26.87-.26.413 0 .742.099.988.297a.957.957 0 01.409.734h2.029c-.062-.78-.391-1.384-.989-1.815-.411-.307-.91-.507-1.499-.6v-.207a.74.74 0 10-1.481 0v.19a3.923 3.923 0 00-1.012.269c-.492.199-.874.489-1.146.87-.272.381-.408.829-.408 1.343 0 .547.131.986.395 1.317.272.324.593.568.962.734.377.157.865.32 1.462.485.44.116.782.224 1.028.323.255.091.465.22.632.385.167.166.25.378.25.634a.946.946 0 01-.368.771c-.246.19-.584.286-1.015.286-.421 0-.755-.103-1.001-.31-.237-.208-.369-.49-.395-.846h-1.977c.01.53.163.99.462 1.38.298.39.702.688 1.212.895z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});