define("ember-svg-jar/inlined/enter-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M31 7a1 1 0 00-1-1H12a1 1 0 00-1 1v5H2a1 1 0 00-1 1v12a1 1 0 001 1h28a1 1 0 001-1V7zm-2 1v16H3V14h9a1 1 0 001-1V8h16z\"/><path d=\"M8.414 18l.293-.293a1 1 0 00-1.414-1.414l-2 2a.999.999 0 000 1.414l2 2a1 1 0 001.414-1.414L8.414 20H23.5a1 1 0 001-1v-3a1 1 0 00-2 0v2H8.414z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "2"
    }
  };
});