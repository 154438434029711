define("@latermedia/ember-later-analytics/components/ui/media-kit/about-section", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <p class={{if this.shouldShowExpandedDescription "cCI--decription cCI--decription--showMore" "cCI--decription"}}>
    {{@about}}
  </p>
  
  {{#if this.displayShowMore}}
    {{! Toggle to expand collapse description }}
    <a {{on "click" this.toggleDescription}} class="cCI--decriptionLink">
      {{#if this.shouldShowExpandedDescription}}
        {{t "media_kit.about.show_less" locale="en-us"}}
        {{svg-jar "nav-left" class="o--icon--xxs"}}
      {{else}}
        {{t "media_kit.about.show_less" locale="en-us"}}
        {{svg-jar "nav-right" class="o--icon--xxs"}}
      {{/if}}
    </a>
  {{/if}}
  
  */
  {
    "id": "Hc8hIab5",
    "block": "[[[10,2],[15,0,[52,[30,0,[\"shouldShowExpandedDescription\"]],\"cCI--decription cCI--decription--showMore\",\"cCI--decription\"]],[12],[1,\"\\n  \"],[1,[30,1]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"displayShowMore\"]],[[[1,\"  \"],[11,3],[24,0,\"cCI--decriptionLink\"],[4,[38,1],[\"click\",[30,0,[\"toggleDescription\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"shouldShowExpandedDescription\"]],[[[1,\"      \"],[1,[28,[35,2],[\"media_kit.about.show_less\"],[[\"locale\"],[\"en-us\"]]]],[1,\"\\n      \"],[1,[28,[35,3],[\"nav-left\"],[[\"class\"],[\"o--icon--xxs\"]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,2],[\"media_kit.about.show_less\"],[[\"locale\"],[\"en-us\"]]]],[1,\"\\n      \"],[1,[28,[35,3],[\"nav-right\"],[[\"class\"],[\"o--icon--xxs\"]]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@about\"],false,[\"if\",\"on\",\"t\",\"svg-jar\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/ui/media-kit/about-section.hbs",
    "isStrictMode": false
  });
  const ABOUT_LENGTH_TOGGLE_MIN = 190;
  let AboutSectionComponent = _exports.default = (_class = class AboutSectionComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "shouldShowExpandedDescription", _descriptor, this);
    }
    toggleDescription() {
      this.shouldShowExpandedDescription = !this.shouldShowExpandedDescription;
    }
    get characterLength() {
      return this.args?.about?.length;
    }
    get displayShowMore() {
      return this.characterLength > ABOUT_LENGTH_TOGGLE_MIN;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "shouldShowExpandedDescription", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleDescription", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDescription"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AboutSectionComponent);
});