define("ember-svg-jar/inlined/text-post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M7.5 9a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm0 2a3.5 3.5 0 100-7 3.5 3.5 0 000 7z\" clip-rule=\"evenodd\"/><rect width=\"16\" height=\"2.5\" x=\"4.5\" y=\"13\" rx=\"1.25\"/><rect width=\"18.5\" height=\"2.5\" x=\"4.5\" y=\"17\" rx=\"1.25\"/><rect width=\"12\" height=\"2.5\" x=\"4.5\" y=\"21\" rx=\"1.25\"/><path d=\"M23.8 0H4.2A4.2 4.2 0 000 4.2v19.6A4.2 4.2 0 004.2 28h19.6a4.2 4.2 0 004.2-4.2V4.2A4.2 4.2 0 0023.8 0zm1.95 23.8a2 2 0 01-1.95 1.95H4.2a2 2 0 01-1.95-1.95V4.2a2 2 0 012-1.95H23.8a2 2 0 011.95 2V23.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});