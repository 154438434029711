define("ember-svg-jar/inlined/skittle_tiktok", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.505 10.3v-.624a4.827 4.827 0 00-.654-.048A4.857 4.857 0 005 14.48c0 1.641.82 3.094 2.071 3.972a4.832 4.832 0 01-1.303-3.303 4.856 4.856 0 014.737-4.849z\" fill=\"#00F2EA\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.62 17.364c1.193 0 2.167-.949 2.211-2.132l.004-10.563h1.93A3.668 3.668 0 0114.702 4h-2.635l-.004 10.563a2.216 2.216 0 01-2.212 2.132c-.358 0-.71-.087-1.027-.254a2.213 2.213 0 001.796.923zm7.75-9.11v-.587c-.71 0-1.404-.206-1.997-.594.52.6 1.22 1.014 1.996 1.181z\" fill=\"#00F2EA\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.373 7.073a3.652 3.652 0 01-.902-2.404h-.706a3.674 3.674 0 001.608 2.404zM9.85 12.264a2.218 2.218 0 00-2.215 2.216 2.217 2.217 0 001.188 1.961 2.202 2.202 0 01-.42-1.292 2.218 2.218 0 012.215-2.216c.229 0 .447.038.654.103v-2.691a4.814 4.814 0 00-.654-.048l-.114.003v2.067a2.182 2.182 0 00-.654-.103z\" fill=\"#FF004F\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.37 8.254v2.049a6.269 6.269 0 01-3.667-1.18v5.357a4.857 4.857 0 01-4.851 4.851 4.822 4.822 0 01-2.78-.879A4.838 4.838 0 0010.62 20a4.857 4.857 0 004.851-4.851V9.792a6.27 6.27 0 003.667 1.18V8.335c-.264 0-.52-.028-.769-.08z\" fill=\"#FF004F\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.703 14.48V9.123a6.268 6.268 0 003.667 1.18V8.254a3.667 3.667 0 01-1.997-1.18 3.675 3.675 0 01-1.608-2.405h-1.93l-.004 10.563a2.211 2.211 0 01-4.007 1.21 2.219 2.219 0 01-1.188-1.962 2.218 2.218 0 012.215-2.216c.228 0 .447.038.654.103V10.3a4.856 4.856 0 00-4.737 4.849c0 1.275.496 2.436 1.303 3.303.814.573 1.785.88 2.78.88a4.857 4.857 0 004.852-4.852z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});