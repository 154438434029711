define("ember-svg-jar/inlined/lib-more-apps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M8.91 0H3.817C1.655 0 0 1.655 0 3.818V8.91c0 2.164 1.655 3.818 3.818 3.818H8.91c2.164 0 3.818-1.654 3.818-3.818V3.82C12.727 1.654 11.073 0 8.91 0zm1.272 8.91c0 .763-.51 1.272-1.273 1.272H3.82c-.764 0-1.274-.51-1.274-1.273V3.82c0-.764.51-1.274 1.273-1.274H8.91c.764 0 1.273.51 1.273 1.273V8.91zm14-8.91H19.09c-2.164 0-3.818 1.655-3.818 3.818V8.91c0 2.164 1.654 3.818 3.818 3.818h5.09c2.165 0 3.819-1.654 3.819-3.818V3.82C28 1.654 26.346 0 24.182 0zm1.273 8.91c0 .763-.51 1.272-1.273 1.272H19.09c-.764 0-1.273-.51-1.273-1.273V3.82c0-.764.51-1.274 1.273-1.274h5.09c.764 0 1.273.51 1.273 1.273V8.91zM8.909 15.272H3.82C1.654 15.273 0 16.927 0 19.09v5.09C0 26.347 1.655 28 3.818 28H8.91c2.164 0 3.818-1.654 3.818-3.818V19.09c0-2.164-1.654-3.818-3.818-3.818zm1.273 8.909c0 .764-.51 1.273-1.273 1.273H3.82c-.764 0-1.274-.51-1.274-1.273V19.09c0-.764.51-1.273 1.273-1.273H8.91c.764 0 1.273.51 1.273 1.273v5.09zm16.545-3.817H22.91v-3.819c0-.763-.509-1.272-1.273-1.272-.763 0-1.272.509-1.272 1.273v3.818h-3.819c-.763 0-1.272.509-1.272 1.272 0 .764.509 1.273 1.273 1.273h3.818v3.818c0 .764.509 1.273 1.272 1.273.764 0 1.273-.51 1.273-1.273V22.91h3.818c.764 0 1.273-.509 1.273-1.273 0-.763-.51-1.272-1.273-1.272z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});