define("ember-svg-jar/inlined/danger-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.556 0a3 3 0 012.122.879l6.443 6.443A3 3 0 0128 9.443v9.113a3 3 0 01-.879 2.122l-6.443 6.443a3 3 0 01-2.122.879H9.444a3 3 0 01-2.122-.879L.88 20.678A3 3 0 010 18.556V9.443a3 3 0 01.879-2.12L7.322.878A3 3 0 019.444 0h9.112zM14 18.112a1.56 1.56 0 00-1.555 1.574c0 .876.697 1.564 1.555 1.564.848 0 1.555-.688 1.555-1.564 0-.877-.707-1.574-1.555-1.574zm0-11.95c-.757 0-1.371.614-1.371 1.37v7.654a1.371 1.371 0 002.742 0V7.533c0-.757-.614-1.371-1.371-1.371z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});