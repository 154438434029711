define("ember-svg-jar/inlined/labs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M25.59 23.5l-7.37-12.79a1.69 1.69 0 01-.22-.85v-7.7h.92a1.08 1.08 0 000-2.16H9.08a1.08 1.08 0 000 2.16H10v7.7a1.69 1.69 0 01-.23.85L2.41 23.5A3 3 0 005 28h18a3 3 0 002.59-4.5zM11.65 11.79a3.85 3.85 0 00.52-1.93V2.41a.25.25 0 01.25-.25h3.18a.25.25 0 01.25.25v7.45a3.85 3.85 0 00.52 1.93l2.26 3.93a4 4 0 01-2.34.71c-2.28 0-2.28-1.36-4.55-1.36a4 4 0 00-2.39.74zm12.07 13.63a.8.8 0 01-.73.42H5a.8.8 0 01-.73-.42.82.82 0 010-.84l3.48-6c1.7-.21 1.92-1.31 4-1.31 2.28 0 2.28 1.36 4.55 1.36 1.75 0 2.18-.8 3.32-1.16l4.12 7.15a.82.82 0 01-.02.8z\"/>",
    "attrs": {
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 28 28"
    }
  };
});