define("ember-svg-jar/inlined/monetary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.75 21.396a7.164 7.164 0 01-1.551-.42c-.86-.35-1.542-.854-2.046-1.511-.504-.657-.763-1.433-.778-2.329h3.335c.044.602.267 1.077.667 1.427.415.35.978.524 1.69.524.726 0 1.297-.16 1.712-.482.415-.336.622-.77.622-1.3 0-.434-.14-.791-.422-1.07a2.852 2.852 0 00-1.067-.651c-.416-.168-.994-.35-1.735-.545-1.008-.28-1.83-.553-2.467-.819a4.535 4.535 0 01-1.624-1.238c-.444-.559-.667-1.3-.667-2.223 0-.867.23-1.623.69-2.266.46-.643 1.104-1.133 1.934-1.469a6.616 6.616 0 011.707-.452V5h2.5v1.6c.992.156 1.835.493 2.53 1.012 1.008.727 1.563 1.748 1.667 3.063h-3.424c-.03-.504-.26-.916-.689-1.238-.415-.336-.97-.503-1.668-.503-.607 0-1.096.146-1.467.44-.356.294-.534.72-.534 1.28 0 .392.134.72.4.986a3.65 3.65 0 001.023.63c.416.153.994.335 1.735.545 1.008.28 1.83.56 2.468.839.637.28 1.185.7 1.645 1.259.46.559.689 1.293.689 2.202a3.97 3.97 0 01-.645 2.182c-.43.671-1.06 1.21-1.89 1.616-.546.257-1.16.43-1.84.518V23h-2.5v-1.604z\"/><path fill-rule=\"evenodd\" d=\"M28 14c0 7.732-6.268 14-14 14S0 21.732 0 14 6.268 0 14 0s14 6.268 14 14zm-2.5 0c0 6.351-5.149 11.5-11.5 11.5S2.5 20.351 2.5 14 7.649 2.5 14 2.5 25.5 7.649 25.5 14z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});