define("ember-svg-jar/inlined/tt-feed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1 1v5M1 10v5M6 1v5M6 10v5M11 1v5M11 10v5\" stroke=\"#64727C\" stroke-width=\"2\" stroke-linecap=\"round\"/>",
    "attrs": {
      "width": "12",
      "height": "16",
      "viewBox": "0 0 12 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});