define("ember-svg-jar/inlined/USING_LATER", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M25.462 1.533a5.225 5.225 0 00-8.224 1.084A12.623 12.623 0 002.063 7.455a12.63 12.63 0 009.266 19.478 12.624 12.624 0 0013.325-8.726 12.63 12.63 0 00-.276-8.448 5.225 5.225 0 001.097-8.222l-.013-.004zm-5.76 19.921a10.012 10.012 0 01-13.17.871l7.402-7.402V19.6h2.612v-7.838a1.307 1.307 0 00-1.306-1.307H7.399v2.613h4.684l-7.4 7.403a10.017 10.017 0 015.43-15.76 10.011 10.011 0 016.428.462 5.226 5.226 0 005.224 5.282h.057a10.018 10.018 0 01-2.12 11zM23.62 7.085a2.612 2.612 0 110-3.697 2.613 2.613 0 01-.009 3.688l.01.009z\" fill=\"#0CA7FF\"/><path d=\"M19.702 21.454a10.012 10.012 0 01-13.17.871l7.402-7.402V19.6h2.612v-7.838a1.306 1.306 0 00-1.306-1.307H7.399v2.613h4.684l-7.4 7.402a10.016 10.016 0 015.43-15.76 10.011 10.011 0 016.428.463 5.226 5.226 0 005.224 5.282h.057a10.018 10.018 0 01-2.12 11z\" fill=\"#fff\" fill-opacity=\".5\"/>",
    "attrs": {
      "width": "27",
      "height": "27",
      "viewBox": "0 0 27 27",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});