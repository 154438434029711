define("ember-svg-jar/inlined/skittle_video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"12\" cy=\"12\" r=\"12\" fill=\"#FD927E\"/><g clip-path=\"url(#clip0_276_3349)\"><path d=\"M15.721 9.991a.264.264 0 00-.267-.264h-1.503v-.915c0-1-.85-1.812-1.883-1.812H6.9v1.364h5.11c.13 0 .287.159.287.287v1.076H6.273A.27.27 0 006 9.991V16.735a.27.27 0 00.273.265h9.181c.15 0 .267-.12.267-.264v-1.833L19 15.89V10.878l-3.279.986V9.99zm-3.157 4.424H9.137a1.03 1.03 0 01-1.042-1.017 1.03 1.03 0 011.042-1.017c.574 0 1.042.46 1.042 1.022 0 .284-.122.529-.314.728h1.97a1.037 1.037 0 01-.313-.728c0-.562.467-1.02 1.042-1.02.574 0 1.042.452 1.042 1.015a1.03 1.03 0 01-1.042 1.017z\" fill=\"#fff\"/></g><defs><clipPath id=\"clip0_276_3349\"><path fill=\"#fff\" transform=\"translate(6 7)\" d=\"M0 0h13v10H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});