define("ember-svg-jar/inlined/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M13.97.048c7.486 0 13.5 6.014 13.5 13.5s-6.014 13.5-13.5 13.5c-7.487 0-13.5-6.014-13.5-13.5S6.482.048 13.97.048zm0 2.455A11 11 0 002.923 13.548a11 11 0 0011.045 11.046 11 11 0 0011.046-11.046A11 11 0 0013.969 2.503zm-.005 7.976c.757 0 1.37.614 1.37 1.372v7.652a1.371 1.371 0 11-2.742 0v-7.652c0-.758.614-1.372 1.372-1.372zm.004-4.693c.858 0 1.555.688 1.555 1.564a1.56 1.56 0 01-1.555 1.574 1.567 1.567 0 01-1.555-1.574c0-.876.707-1.564 1.555-1.564z\" opacity=\".9\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});