define("@latermedia/ember-later-analytics/components/ui/media-kit/tiktok-post-card-item", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cPR--card">
    <div class="cPR--card__image cPR--card__image--tall qa--mediaKit_clientPage_tiktokFeedPerformance_pic">
      {{#if @post.thumbnailUrl}}
        <figure class="o--mediaContainer">
          <img src={{@post.thumbnailUrl}} class="o--media" onerror={{set @post "thumbnailUrl" undefined}}>
        </figure>
      {{else}}
        <figure class="o--mediaContainer">
          <div class="cPR--card__imageEmpty">
            {{svg-jar "star-alt" class="o--icon--lg o--icon--white"}}
          </div>
        </figure>
      {{/if}}
    </div>
    <p class="u--m__b__xs">
      {{t "media_kit.post.reach" locale="en-us"}}
      {{@post.reachCount}}
    </p>
    {{#if @post.link}}
      <a rel="noopener noreferrer" class="cPR--card__link" href={{@post.link}} target="_blank">
        {{t "media_kit.post.view_post" locale="en-us"}}
        {{svg-jar "arrow-external" class="o--icon--xxs"}}
      </a>
    {{/if}}
  </div>
  */
  {
    "id": "CbsdKaLR",
    "block": "[[[10,0],[14,0,\"cPR--card\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"cPR--card__image cPR--card__image--tall qa--mediaKit_clientPage_tiktokFeedPerformance_pic\"],[12],[1,\"\\n\"],[41,[30,1,[\"thumbnailUrl\"]],[[[1,\"      \"],[10,\"figure\"],[14,0,\"o--mediaContainer\"],[12],[1,\"\\n        \"],[10,\"img\"],[15,\"src\",[30,1,[\"thumbnailUrl\"]]],[14,0,\"o--media\"],[15,\"onerror\",[28,[37,1],[[30,1],\"thumbnailUrl\",[27]],null]],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"figure\"],[14,0,\"o--mediaContainer\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"cPR--card__imageEmpty\"],[12],[1,\"\\n          \"],[1,[28,[35,2],[\"star-alt\"],[[\"class\"],[\"o--icon--lg o--icon--white\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[10,2],[14,0,\"u--m__b__xs\"],[12],[1,\"\\n    \"],[1,[28,[35,3],[\"media_kit.post.reach\"],[[\"locale\"],[\"en-us\"]]]],[1,\"\\n    \"],[1,[30,1,[\"reachCount\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,1,[\"link\"]],[[[1,\"    \"],[10,3],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"cPR--card__link\"],[15,6,[30,1,[\"link\"]]],[14,\"target\",\"_blank\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"media_kit.post.view_post\"],[[\"locale\"],[\"en-us\"]]]],[1,\"\\n      \"],[1,[28,[35,2],[\"arrow-external\"],[[\"class\"],[\"o--icon--xxs\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@post\"],false,[\"if\",\"set\",\"svg-jar\",\"t\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/ui/media-kit/tiktok-post-card-item.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});