define("ember-svg-jar/inlined/refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M28 4.225v7.024c0 .703-.468 1.171-1.17 1.171h-7.026c-.702 0-1.17-.468-1.17-1.17 0-.703.468-1.171 1.17-1.171h3.981c-1.522-1.523-2.81-2.81-3.161-3.045a10.189 10.189 0 00-3.044-2.107c-1.17-.468-2.342-.702-3.512-.702-1.171 0-2.46.234-3.513.702-1.17.468-2.224 1.17-3.044 2.107l-.234.235c-.703.82-1.288 1.756-1.756 2.692-.117.235-.117.352-.234.586-.235.585-.937.936-1.523.702-.585-.234-.936-.82-.702-1.522l.351-.702c.234-.586.469-1.054.82-1.522.351-.586.82-1.171 1.405-1.756.117-.118.234-.235.234-.352A11.76 11.76 0 019.736 2.82c.702-.235 1.405-.469 2.224-.586.703-.117 1.522-.234 2.225-.234.702 0 1.522.117 2.224.234.703.117 1.522.351 2.225.586 1.405.585 2.81 1.405 3.863 2.575.351.352 1.756 1.64 3.396 3.396V4.225c0-.703.468-1.171 1.17-1.171.703 0 .937.468.937 1.17zM1.072 24.128c.702 0 1.17-.468 1.17-1.17v-4.45c1.64 1.64 3.045 2.927 3.396 3.396A11.76 11.76 0 009.5 24.479c.703.234 1.405.469 2.225.586.702.117 1.522.234 2.224.234 1.522 0 3.044-.234 4.45-.82 1.404-.585 2.81-1.405 3.863-2.575.117-.118.234-.235.234-.352l1.405-1.756c.351-.468.586-1.054.82-1.522l.351-.702c.234-.586-.117-1.288-.702-1.522-.586-.235-1.288.117-1.523.702-.117.234-.117.351-.234.585-.936 2.225-2.81 4.098-5.151 5.035-1.171.468-2.342.702-3.513.702-1.17 0-2.458-.234-3.512-.702-1.17-.468-2.224-1.17-3.044-2.108-.351-.35-1.64-1.522-3.161-3.044h3.98c.703 0 1.171-.468 1.171-1.17 0-.703-.468-1.171-1.17-1.171H1.189c-.703 0-1.171.468-1.171 1.17v7.025c-.117.586.351 1.054 1.054 1.054z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});