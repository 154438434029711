define("@latermedia/ember-later-analytics/utils/adjust-brightness", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = adjustBrightness;
  /**
   * @param hexCode Hex value format: #ffffff or ffffff
   * @param adjustmentRate lighten or darken based on decimal value, < 1 to lighter > 1 to darken e.g. 0.5 to lighten by 50% or 1.5 to darken by 50%.
   */
  function adjustBrightness(hexCode, adjustmentRate) {
    const base = hexCode.startsWith('#') ? 1 : 0;
    let r = parseInt(hexCode.substring(base, 3), 16);
    let g = parseInt(hexCode.substring(base + 2, 5), 16);
    let b = parseInt(hexCode.substring(base + 4, 7), 16);
    r = Math.round(r / adjustmentRate);
    g = Math.round(g / adjustmentRate);
    b = Math.round(b / adjustmentRate);
    r = r < 255 ? r : 255;
    g = g < 255 ? g : 255;
    b = b < 255 ? b : 255;
    const rr = r.toString(16).length === 1 ? `0${r.toString(16)}` : r.toString(16);
    const gg = g.toString(16).length === 1 ? `0${g.toString(16)}` : g.toString(16);
    const bb = b.toString(16).length === 1 ? `0${b.toString(16)}` : b.toString(16);
    return `#${rr}${gg}${bb}`;
  }
});