define("ember-svg-jar/inlined/social-twitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24.585 6.147c1.139-.683 2.05-1.82 2.504-3.187-1.138.683-2.276 1.138-3.642 1.366-1.138-1.138-2.504-1.821-4.097-1.821-2.96 0-5.692 2.504-5.692 5.691 0 .455 0 .91.228 1.366C9.106 9.107 4.78 6.83 2.05 3.416c-.456.91-.683 1.82-.683 2.959 0 1.821.91 3.642 2.504 4.78-.91 0-1.821-.455-2.732-.682 0 2.731 1.821 5.008 4.553 5.69-.455.228-.91.228-1.366.228-.455 0-.683 0-1.138-.227.683 2.276 2.732 4.097 5.463 4.097-1.82 1.594-4.325 2.504-7.284 2.504H0c2.732 1.594 5.691 2.732 8.878 2.732 10.472 0 16.39-8.878 16.39-16.39v-.683c1.139-.91 2.05-2.05 2.732-3.187-1.138.455-2.049.683-3.415.91z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});