define("ember-svg-jar/inlined/globe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.51 0h-1.02C5.983.255 0 6.49 0 14s5.982 13.745 13.49 14h1.02C22.017 27.745 28 21.51 28 14S22.018.255 14.51 0zm10.817 12.727h-5.09C19.981 8.4 18.2 5.091 16.8 2.927c4.582 1.146 8.018 5.091 8.527 9.8zM14 3.182c1.273 1.654 3.436 4.963 3.818 9.545h-7.636c.382-4.454 2.545-7.89 3.818-9.545zm3.818 12.09c-.382 4.455-2.545 7.892-3.818 9.546-1.273-1.654-3.436-4.963-3.818-9.545h7.636zM11.073 2.928c-1.4 2.164-3.182 5.6-3.437 9.8h-5.09c.636-4.709 4.072-8.654 8.527-9.8zm-8.4 12.346h5.09c.255 4.2 2.037 7.636 3.437 9.8-4.582-1.146-8.018-5.091-8.527-9.8zm14.254 9.8c1.4-2.164 3.182-5.6 3.437-9.8h5.09c-.636 4.709-4.072 8.654-8.527 9.8z\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});