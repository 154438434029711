define("ember-svg-jar/inlined/boost", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.592 11.696l2.275.962c.272-.544.554-1.07.845-1.575.292-.505.613-1.01.963-1.516l-1.633-.321-2.45 2.45zm4.141 2.42l3.325 3.297c.817-.312 1.692-.788 2.625-1.43a18.646 18.646 0 002.625-2.187c1.361-1.361 2.426-2.873 3.194-4.536.768-1.662 1.104-3.193 1.006-4.593-1.4-.098-2.936.238-4.608 1.006-1.672.768-3.189 1.833-4.55 3.194a18.652 18.652 0 00-2.188 2.625c-.641.933-1.118 1.808-1.429 2.625zm5.192-1.895c-.447-.447-.67-.997-.67-1.648 0-.651.223-1.2.67-1.648.447-.447 1.001-.67 1.662-.67.662 0 1.216.223 1.663.67.447.447.67.997.67 1.648 0 .651-.223 1.2-.67 1.648-.447.447-1.002.67-1.663.67-.66 0-1.215-.223-1.662-.67zm.554 9.362l2.45-2.45-.32-1.633a18.47 18.47 0 01-1.517.948 39.61 39.61 0 01-1.575.831l.962 2.304zm9.13-19.045a13.44 13.44 0 01-.686 6.868c-.826 2.227-2.25 4.35-4.273 6.373l.583 2.888c.078.389.059.768-.058 1.137-.117.37-.311.69-.583.963l-4.9 4.9-2.45-5.746-4.988-4.988-5.746-2.45 4.871-4.9c.272-.272.598-.466.977-.583.38-.117.763-.136 1.152-.058l2.888.583c2.022-2.022 4.141-3.451 6.358-4.288a13.27 13.27 0 016.854-.7zm-21.03 16.1c.68-.681 1.512-1.026 2.494-1.036.982-.01 1.813.326 2.494 1.006.68.68 1.016 1.512 1.006 2.494-.01.982-.355 1.813-1.036 2.494-.486.486-1.297.904-2.435 1.254-1.137.35-2.708.661-4.71.933.272-2.002.583-3.573.933-4.71.35-1.138.768-1.95 1.254-2.436zm1.663 1.633c-.195.194-.39.55-.584 1.064a7.54 7.54 0 00-.408 1.56 8.154 8.154 0 001.56-.393c.516-.185.87-.374 1.065-.569.233-.233.36-.515.38-.846a1.057 1.057 0 00-.322-.845 1.103 1.103 0 00-.846-.336c-.33.01-.612.132-.845.365z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});