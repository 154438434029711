define("ember-svg-jar/inlined/skittle_yt_short", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12z\" fill=\"red\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.984 3.397c1.64-.885 3.67-.24 4.534 1.44.863 1.68.233 3.759-1.407 4.643l-1.348.734a3.348 3.348 0 012.85 1.834c.863 1.68.235 3.759-1.407 4.644l-7.19 3.91c-1.64.886-3.671.241-4.534-1.44-.863-1.68-.234-3.758 1.406-4.643l1.349-.733a3.348 3.348 0 01-2.85-1.835c-.863-1.68-.234-3.758 1.406-4.643l7.191-3.911zm-3.88 5.833l4.352 2.781-4.353 2.77V9.23z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});