define("ember-svg-jar/inlined/minus-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M14 .5C6.514.5.5 6.514.5 14S6.514 27.5 14 27.5 27.5 21.486 27.5 14 21.486.5 14 .5zm0 24.545A11 11 0 012.955 14 11 11 0 0114 2.955 11 11 0 0125.045 14 11 11 0 0114 25.045zM9.09 12.773c-.735 0-1.226.49-1.226 1.227 0 .736.49 1.227 1.227 1.227h9.818c.736 0 1.227-.49 1.227-1.227 0-.736-.49-1.227-1.227-1.227H9.091z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});