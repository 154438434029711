define("ember-svg-jar/inlined/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M26.834 13.918c.004.287-.109.562-.312.764a.904.904 0 01-.205.142c-.327.642-.93 1.099-1.636 1.242h-1.076v8.611a2.153 2.153 0 01-2.153 2.153H6.382a2.153 2.153 0 01-2.153-2.153v-8.611H3.153a2.153 2.153 0 01-2.142-2.09c0-.017-.011-.033-.011-.05v-.013c.007-.27.12-.527.312-.717l11.733-11.74a.57.57 0 01.108-.153 1.116 1.116 0 011.528 0c.045.045.08.097.108.155l11.733 11.738c.193.19.305.447.312.717v.005zM12.841 24.683h2.152V19.3h-2.152v5.383zm1.076-21.119L3.562 13.913h2.82v10.764h4.306V18.22c0-.595.482-1.077 1.076-1.077h4.306c.594 0 1.076.482 1.076 1.077v6.458h4.306V13.913h2.82L13.917 3.564z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});