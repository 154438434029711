define("ember-svg-jar/inlined/media-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0)\"><path fill-rule=\"evenodd\" d=\"M9.273 6.727c-2.01 0-3.546 1.536-3.546 3.545 0 2.01 1.537 3.546 3.546 3.546s3.545-1.537 3.545-3.546-1.536-3.545-3.545-3.545zm0 4.727c-.71 0-1.182-.473-1.182-1.182s.473-1.181 1.182-1.181 1.182.472 1.182 1.181c0 .71-.473 1.182-1.182 1.182z\" clip-rule=\"evenodd\"/><path d=\"M22.063 1C21.476 1 21 1.475 21 2.062v2.813h-2.875a1.125 1.125 0 000 2.25H21v2.812a1.062 1.062 0 102.125 0V7.125h2.75a1.125 1.125 0 000-2.25h-2.75V2.062A1.063 1.063 0 0022.063 1z\"/><path fill-rule=\"evenodd\" d=\"M26.758 21.241l-1.767 1.768-5.882-5.881-9.49 9.491-1.768-1.768L19.11 13.593l7.648 7.648z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M25.818 14c-.709 0-1.182.473-1.182 1.182v8.273c0 .709-.472 1.182-1.181 1.182H4.545c-.709 0-1.181-.473-1.181-1.182V4.545c0-.709.472-1.181 1.182-1.181h8.272c.71 0 1.182-.473 1.182-1.182C14 1.472 13.527 1 12.818 1H4.546C2.536 1 1 2.536 1 4.546v18.909C1 25.464 2.536 27 4.546 27h18.909C25.464 27 27 25.464 27 23.455v-8.273c0-.71-.473-1.182-1.182-1.182z\" clip-rule=\"evenodd\"/></g><defs><clipPath id=\"clip0\"><path d=\"M0 0h28v28H0z\"/></clipPath></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});