define("@latermedia/ember-later-analytics/models/data", ["exports", "@ember/utils", "@latermedia/ember-later-analytics/utils/object-methods", "@latermedia/ember-later-analytics/utils/age-gender", "@latermedia/ember-later-analytics/utils/demographics", "@latermedia/ember-later-analytics/utils/followers-online-hourly", "@latermedia/ember-later-analytics/utils/industry-benchmarks", "@latermedia/ember-later-analytics/utils/media"], function (_exports, _utils, _objectMethods, _ageGender, _demographics, _followersOnlineHourly, _industryBenchmarks, _media) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class DataModel {
    constructor(data, meta, config) {
      _defineProperty(this, "benchmarks", {
        industryAverageEngagement: '',
        industryName: ''
      });
      _defineProperty(this, "profileCountsValues", {
        time: null,
        followedByCount: null,
        mediaCount: null,
        followsCount: null
      });
      _defineProperty(this, "profileCounts", {
        time: 0,
        values: null
      });
      _defineProperty(this, "profileInsights", {
        followerGrowthNum: 0,
        followerGrowthPct: 0,
        impressions: 0,
        profileViews: 0,
        websiteClicks: 0
      });
      _defineProperty(this, "linkinbioInsights", {
        blockClicks: 0,
        clickThroughV2: '',
        libPageUrl: '',
        pageviews: 0,
        postClicks: 0
      });
      _defineProperty(this, "linkinbioPageUrl", void 0);
      _defineProperty(this, "audience", {
        topCountries: {
          endTime: null,
          values: []
        },
        age: {
          values: []
        },
        gender: {
          values: []
        },
        followersOnlineHourly: {
          values: []
        }
      });
      _defineProperty(this, "media", {
        posts: {
          summary: {
            averageReachCount: null,
            averageLikesCount: null,
            averageCommentsCount: null,
            averageEngagementPercentage: null,
            averageImpressionsCount: null,
            averageSharesCount: null
          },
          highlights: (0, _media.getDefaultPostsHighlights)()
        },
        stories: {
          summary: {
            averageImpressionsCount: null,
            averageReachCount: null,
            averageRepliesCount: null
          },
          highlights: (0, _media.getDefaultStoriesHighlights)()
        },
        reels: {
          summary: {
            averageReachCount: null,
            averagePlayCount: null,
            averageLikeCount: null
          },
          highlights: (0, _media.getDefaultReelsHighlights)()
        }
      });
      this.profileCounts = {
        time: data?.profile_counts?.time,
        values: (0, _objectMethods.objectSnakeToCamel)(data?.profile_counts, this.profileCountsValues)
      };
      this.profileInsights = {
        ...(0, _objectMethods.objectSnakeToCamel)(data?.profile_insights, this.profileInsights)
      };
      this.linkinbioInsights = {
        ...(0, _objectMethods.objectSnakeToCamel)(data?.linkinbio, this.linkinbioInsights)
      };
      this.linkinbioPageUrl = data?.linkinbio?.lib_page_url;
      this.audience.topCountries = {
        endTime: data?.audience?.top_countries?.end_time,
        values: data?.audience?.top_countries?.countries ? (0, _demographics.default)(data.audience.top_countries.countries) : []
      };
      this.audience.age = {
        values: data?.audience?.age ? (0, _ageGender.formatAgeData)(data?.audience?.age) : []
      };
      this.audience.gender = {
        values: data?.audience?.gender ? (0, _ageGender.formatGenderData)(data?.audience?.gender) : []
      };
      this.audience.followersOnlineHourly = {
        values: data?.audience?.online_followers_hourly ? (0, _followersOnlineHourly.default)(data?.audience?.online_followers_hourly, meta?.userTimeZoneStandard) : []
      };
      if ((0, _utils.isPresent)(data?.benchmarks)) {
        this.benchmarks = (0, _industryBenchmarks.default)(data.benchmarks);
      }
      this.media.posts.summary = (0, _media.formatPostsSummary)(data?.posts?.summary);
      this.media.stories.summary = (0, _media.formatStoriesSummary)(data?.stories?.summary);
      this.media.reels.summary = (0, _media.formatReelsSummary)(data?.reels?.summary);
      this.media.posts.highlights = (0, _media.formatPostsHighlights)(data?.posts?.highlights, config);
      this.media.stories.highlights = (0, _media.formatStoriesHighlights)(data?.stories?.highlights, config);
      this.media.reels.highlights = (0, _media.formatReelsHighlights)(data?.reels?.highlights, config);
    }
  }
  _exports.default = DataModel;
});