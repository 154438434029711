define("ember-svg-jar/inlined/snr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M15 6c5 0 9 4 9 9 0 2.1-.8 4.1-2 5.6l5.7 5.7c.4.4.4 1 0 1.4-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3L20.6 22c-1.5 1.3-3.5 2-5.6 2-5 0-9-4-9-9s4-9 9-9zM4.582 16.334c.1.811.291 1.592.563 2.333H2.333v7h7v-1.799c.721.458 1.503.83 2.333 1.103v.696A2.333 2.333 0 019.334 28h-7A2.333 2.333 0 010 25.667v-7a2.333 2.333 0 012.333-2.334h2.249zM15 8.25c-3.76 0-6.75 2.99-6.75 6.75s2.99 6.75 6.75 6.75 6.75-2.99 6.75-6.75S18.76 8.25 15 8.25zM9.333 0a2.333 2.333 0 012.334 2.333l-.001 2.696c-.83.273-1.612.645-2.333 1.103V2.333h-7v7h3.799c-.458.721-.83 1.503-1.103 2.333H2.333A2.333 2.333 0 010 9.334v-7A2.333 2.333 0 012.333 0h7zm16.334 0A2.333 2.333 0 0128 2.333v7a2.333 2.333 0 01-2.333 2.334l-.696-.001a10.408 10.408 0 00-1.103-2.333h1.799v-7h-7v2.812a10.536 10.536 0 00-2.333-.563V2.333A2.333 2.333 0 0118.666 0h7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});