define("@latermedia/ember-later-analytics/components/best-performing-media/number", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{concat (if @isReport "tRE" "tAN") "--card__label"}} ...attributes>
    {{#if @isLoading}}
      {{#if @showLoadingState}}
        <div class="cCH--loading cCH--loading__block__md"></div>
      {{/if}}
    {{else}}
      {{@text}}
      <span class={{concat (if @isReport "tRE" "tAN") "--card__value"}}>
        {{ember-later-analytics/format-number-with-suffix @number}}
      </span>
    {{/if}}
  </div>
  
  */
  {
    "id": "JkGNABOE",
    "block": "[[[11,0],[16,0,[28,[37,0],[[52,[30,1],\"tRE\",\"tAN\"],\"--card__label\"],null]],[17,2],[12],[1,\"\\n\"],[41,[30,3],[[[41,[30,4],[[[1,\"      \"],[10,0],[14,0,\"cCH--loading cCH--loading__block__md\"],[12],[13],[1,\"\\n\"]],[]],null]],[]],[[[1,\"    \"],[1,[30,5]],[1,\"\\n    \"],[10,1],[15,0,[28,[37,0],[[52,[30,1],\"tRE\",\"tAN\"],\"--card__value\"],null]],[12],[1,\"\\n      \"],[1,[28,[35,2],[[30,6]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"@isReport\",\"&attrs\",\"@isLoading\",\"@showLoadingState\",\"@text\",\"@number\"],false,[\"concat\",\"if\",\"ember-later-analytics/format-number-with-suffix\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/best-performing-media/number.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});