define("ember-svg-jar/inlined/copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.11 4.71a3.222 3.222 0 013.227 3.228v16.335A3.241 3.241 0 0117.11 27.5H5.727A3.241 3.241 0 012.5 24.273V7.938a3.241 3.241 0 013.227-3.227H17.11zm1.142 19.533V7.938a1.14 1.14 0 00-1.142-1.142H5.727a1.14 1.14 0 00-1.142 1.142v16.305c0 .633.51 1.143 1.142 1.143H17.11a1.14 1.14 0 001.142-1.143zM24.38 3.727v16.335c0 1.24-.583 2.136-1.526 2.702a4.098 4.098 0 01-1.346.506l-.117.017h-.577v-2.163h.484c.597-.02.997-.454.997-1.092V3.727a1.14 1.14 0 00-1.142-1.142H9.36c-.544 0-.96.467-1.156 1.262v.38H6.095l.01-.51C6.14 1.947 7.597.5 9.36.5h11.792a3.241 3.241 0 013.227 3.227zM-29.67 47.132a4.229 4.229 0 017.97-2.625l4.226-.82 2.373-12.184a1.048 1.048 0 112.059.4l-1.9 9.795.602-.619a1 1 0 111.433 1.394l-.393.404 9.408-1.824c.528-.103 1.04.21 1.199.71l.03.12c.11.567-.26 1.118-.83 1.228l-12.185 2.374-.818 4.226a4.229 4.229 0 11-2.073-.402l.66-3.389-3.389.66a4.229 4.229 0 01-3.717 3.944l-.226.02a4.229 4.229 0 01-4.43-3.412zm9.64 7.481a2.088 2.088 0 103.463-2.335 2.088 2.088 0 00-3.462 2.335zm9.176-17.118a1 1 0 111.433 1.394l-1.743 1.792a1 1 0 11-1.433-1.394l1.743-1.792zm3.486-3.585a1 1 0 011.434 1.395l-1.743 1.792a1 1 0 11-1.434-1.394l1.743-1.793zm3.486-3.584a1 1 0 011.434 1.395l-1.743 1.792a1 1 0 01-1.434-1.395l1.743-1.792zm-20.385 14.251a2.088 2.088 0 10-2.336 3.463 2.088 2.088 0 002.336-3.463z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});