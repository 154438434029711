define("@latermedia/ember-later-analytics/components/stats-tile-item", ["exports", "@ember/component", "@glimmer/component", "@ember/utils", "@latermedia/ember-later-analytics/utils/convert-meta-to-string", "@ember/template-factory"], function (_exports, _component, _component2, _utils, _convertMetaToString, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{concat "cCH--statGrid__item " (if @isSmall "cCH--statGrid__item--sm")}} ...attributes>
    <div class={{concat "cCH--statGrid__value " @additionalClasses}}>
      {{this.mainValue}}
      {{#if this.trendValue}}
        <span class={{concat "cCH--statGrid__stat " this.trendClass}}>
          {{this.trendValue}}
        </span>
      {{/if}}
    </div>
    <div class="cCH--statGrid__unit">
      {{#if @name}}
        {{@name}}
      {{/if}}
      {{#if @glossaryLink}}
        <GlossaryLink @glossaryLink={{@glossaryLink}} />
      {{/if}}
      {{#if @tooltip}}
        <span>
          {{yield}}
          <EmberTooltip @delay={{330}} @side="top">
            {{@tooltip}}
          </EmberTooltip>
        </span>
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "vhubIHAt",
    "block": "[[[11,0],[16,0,[28,[37,0],[\"cCH--statGrid__item \",[52,[30,1],\"cCH--statGrid__item--sm\"]],null]],[17,2],[12],[1,\"\\n  \"],[10,0],[15,0,[28,[37,0],[\"cCH--statGrid__value \",[30,3]],null]],[12],[1,\"\\n    \"],[1,[30,0,[\"mainValue\"]]],[1,\"\\n\"],[41,[30,0,[\"trendValue\"]],[[[1,\"      \"],[10,1],[15,0,[28,[37,0],[\"cCH--statGrid__stat \",[30,0,[\"trendClass\"]]],null]],[12],[1,\"\\n        \"],[1,[30,0,[\"trendValue\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"cCH--statGrid__unit\"],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"      \"],[1,[30,4]],[1,\"\\n\"]],[]],null],[41,[30,5],[[[1,\"      \"],[8,[39,2],null,[[\"@glossaryLink\"],[[30,5]]],null],[1,\"\\n\"]],[]],null],[41,[30,6],[[[1,\"      \"],[10,1],[12],[1,\"\\n        \"],[18,7,null],[1,\"\\n        \"],[8,[39,4],null,[[\"@delay\",\"@side\"],[330,\"top\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,6]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@isSmall\",\"&attrs\",\"@additionalClasses\",\"@name\",\"@glossaryLink\",\"@tooltip\",\"&default\"],false,[\"concat\",\"if\",\"glossary-link\",\"yield\",\"ember-tooltip\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/stats-tile-item.hbs",
    "isStrictMode": false
  });
  class StatsTileItem extends _component2.default {
    get mainValue() {
      if ((0, _utils.isEmpty)(this.args.main?.value)) {
        return '-';
      }
      if (this.args.main?.isLoading && !this.args.main?.isBackfilling) {
        return '. . .';
      }
      return (0, _convertMetaToString.default)(this.args.main, false, this.args.significantDigits);
    }
    get trendClass() {
      if (this.trendValue && this.trendValue !== '-') {
        return this.args.trend?.isIncreasing ? 'is--positive' : 'is--negative';
      }
      return '';
    }
    get trendValue() {
      if (!this.args.trend) {
        return '';
      }
      if ((0, _utils.isEmpty)(this.args.trend.value)) {
        return '-';
      }
      const showSign = true;
      const significantDigits = 0;
      return (0, _convertMetaToString.default)(this.args.trend, true, significantDigits, showSign);
    }
  }
  _exports.default = StatsTileItem;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StatsTileItem);
});