define("ember-svg-jar/inlined/lock-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M22.4 8.4v4.2H24V28H4V12.6h1.6V8.4C5.6 3.8 9.4 0 14 0c4.6 0 8.4 3.8 8.4 8.4zM14 2.2c-3.4 0-6.2 2.8-6.2 6.2v4.2h12.4V8.4c0-3.5-2.8-6.2-6.2-6.2zM22 26V14.5H6V26h16z\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});