define("ember-svg-jar/inlined/trash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M16.5 1c2.125 0 3.75 1.625 3.75 3.75V6h5c.75 0 1.25.5 1.25 1.25S26 8.5 25.25 8.5H24v15c0 2.125-1.625 3.75-3.75 3.75H7.75C5.625 27.25 4 25.625 4 23.5v-15H2.75C2 8.5 1.5 8 1.5 7.25S2 6 2.75 6h5V4.75C7.75 2.625 9.375 1 11.5 1zm5 7.5h-15v15c0 .75.5 1.25 1.25 1.25h12.5c.75 0 1.25-.5 1.25-1.25v-15zM16.748 11c.553 0 1.002.455 1.002.998v9.254a1 1 0 01-1.002.998h-.496a1.006 1.006 0 01-1.002-.998v-9.254A1 1 0 0116.252 11h.496zm-5 0c.553 0 1.002.455 1.002.998v9.254a1 1 0 01-1.002.998h-.496a1.006 1.006 0 01-1.002-.998v-9.254A1 1 0 0111.252 11h.496zM16.5 3.5h-5c-.75 0-1.25.5-1.25 1.25V6h7.5V4.75c0-.75-.5-1.25-1.25-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});