define("ember-svg-jar/inlined/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#000\" d=\"M2 22v-2h24v2H2zm0-7v-2h24v2H2zm0-7V6h24v2H2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "none",
      "viewBox": "0 0 28 28"
    }
  };
});