define("ember-svg-jar/inlined/double-arrows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M12.98 23.887a1.224 1.224 0 010-1.774l7.98-7.98-7.98-7.98a1.224 1.224 0 010-1.773 1.224 1.224 0 011.773 0l8.867 8.867a1.224 1.224 0 010 1.773l-8.867 8.867c-.253.253-.633.38-.886.38-.38 0-.634-.127-.887-.38zm-6.713.38c.38 0 .633-.127.886-.38l8.867-8.867a1.224 1.224 0 000-1.773L7.153 4.38a1.224 1.224 0 00-1.773 0 1.224 1.224 0 000 1.773l7.98 7.98-7.98 7.98a1.224 1.224 0 000 1.774c.253.253.507.38.887.38z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});