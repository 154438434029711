define("ember-svg-jar/inlined/connect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0)\"><path fill-rule=\"evenodd\" d=\"M4.76 18.82l3.2-3.44c.56-.56.56-1.4 0-1.96s-1.4-.56-1.96 0L.42 19.24c-.56.56-.56 1.4 0 1.96L6 27.42c.28.28.7.42.98.42.42 0 .7-.14.98-.42.56-.56.56-1.4 0-1.96l-3.2-3.84h8.84c.84 0 1.4-.56 1.4-1.4 0-.84-.7-1.4-1.4-1.4H4.76zm15.36-.07a1.46 1.46 0 010 2.92h-2.66a1.46 1.46 0 110-2.92h2.66zm5.86 1.46a1.46 1.46 0 00-1.46-1.46h-.66a1.46 1.46 0 000 2.92h.66a1.46 1.46 0 001.46-1.46zM23.22 9.02l-3.2 3.44c-.56.56-.56 1.4 0 1.96s1.4.56 1.96 0l5.58-5.82c.56-.56.56-1.4 0-1.96L21.98.42C21.7.14 21.28 0 21 0c-.42 0-.7.14-.98.42-.56.56-.56 1.4 0 1.96l3.2 3.84h-8.84c-.84 0-1.4.56-1.4 1.4 0 .84.7 1.4 1.4 1.4h8.84zm-15.36.07a1.46 1.46 0 110-2.92h2.66a1.46 1.46 0 010 2.92H7.86zM2 7.63c0 .806.654 1.46 1.46 1.46h.66a1.46 1.46 0 000-2.92h-.66A1.46 1.46 0 002 7.63z\" clip-rule=\"evenodd\"/></g><defs><clipPath id=\"clip0\"><path d=\"M0 0h28v28H0z\"/></clipPath></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});