define("ember-svg-jar/inlined/skittle_twitch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_276_3345)\"><circle cx=\"12\" cy=\"12\" r=\"12\" fill=\"#9146FF\"/><g clip-path=\"url(#clip1_276_3345)\"><path d=\"M17.917 13.036l-2.167 2.165h-2.167l-1.896 1.894v-1.894H9.25V7.082h8.667v5.954z\" fill=\"#fff\"/><path d=\"M8.708 6L6 8.706v9.743h3.25v2.706l2.708-2.706h2.167L19 13.577V6H8.708zm9.209 7.036l-2.167 2.165h-2.167l-1.896 1.895V15.2H9.25V7.082h8.667v5.954z\" fill=\"#000\"/><path d=\"M16.292 8.977h-1.084v3.247h1.084V8.977zM13.312 8.977H12.23v3.247h1.083V8.977z\" fill=\"#000\"/></g></g><defs><clipPath id=\"clip0_276_3345\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath><clipPath id=\"clip1_276_3345\"><path fill=\"#fff\" transform=\"translate(6 6)\" d=\"M0 0h13v15.155H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});