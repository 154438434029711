define("ember-svg-jar/inlined/align-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M24.5 13.13H8.17a1.46 1.46 0 110-2.92H24.5a1.46 1.46 0 110 2.92zM26 7a1.46 1.46 0 00-1.5-1.46h-21a1.46 1.46 0 100 2.92h21A1.46 1.46 0 0026 7zm0 9.33a1.45 1.45 0 00-1.46-1.45H3.5a1.46 1.46 0 100 2.91h21a1.46 1.46 0 001.5-1.46zM26 21a1.46 1.46 0 00-1.46-1.46H8.17a1.46 1.46 0 100 2.92H24.5A1.46 1.46 0 0026 21z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});