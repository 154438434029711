define("ember-svg-jar/inlined/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M15.35 12.65V1.85c0-.81-.54-1.35-1.35-1.35s-1.35.54-1.35 1.35v10.8H1.85C1.04 12.65.5 13.19.5 14s.54 1.35 1.35 1.35h10.8v10.8c0 .81.54 1.35 1.35 1.35s1.35-.54 1.35-1.35v-10.8h10.8c.81 0 1.35-.54 1.35-1.35s-.54-1.35-1.35-1.35h-10.8zM54.649 3.435a1.54 1.54 0 01-.004 2.188l-8.419 8.418 8.419 8.42c.571.571.608 1.473.1 2.083l-.096.104a1.54 1.54 0 01-2.188-.004l-8.419-8.419-8.419 8.42a1.544 1.544 0 01-2.083.099l-.105-.096a1.54 1.54 0 01.004-2.188l8.419-8.42-8.419-8.417a1.544 1.544 0 01-.1-2.084l.096-.104a1.54 1.54 0 012.188.004l8.419 8.418 8.419-8.418a1.544 1.544 0 012.083-.1l.105.096z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});