define("ember-svg-jar/inlined/move", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M27.65 13.183L23.567 9.1a1.128 1.128 0 00-1.634 0 1.128 1.128 0 000 1.633l2.1 2.1h-8.866V3.967l2.1 2.1a1.128 1.128 0 001.633 0 1.128 1.128 0 000-1.634L14.817.35a1.128 1.128 0 00-1.634 0L9.1 4.433a1.128 1.128 0 000 1.634 1.128 1.128 0 001.633 0l2.1-2.1v8.866H3.967l2.1-2.1a1.128 1.128 0 000-1.633 1.128 1.128 0 00-1.634 0L.35 13.183a1.128 1.128 0 000 1.634L4.433 18.9a1.128 1.128 0 001.634 0 1.128 1.128 0 000-1.633l-2.1-2.1h8.866v8.866l-2.1-2.1a1.128 1.128 0 00-1.633 0 1.128 1.128 0 000 1.634l4.083 4.083a1.128 1.128 0 001.634 0l4.083-4.083a1.128 1.128 0 000-1.634 1.128 1.128 0 00-1.633 0l-2.1 2.1v-8.866h8.866l-2.1 2.1a1.128 1.128 0 000 1.633 1.128 1.128 0 001.634 0l4.083-4.083a1.128 1.128 0 000-1.634z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});