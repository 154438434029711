define("ember-svg-jar/inlined/dollar-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15 1h-2.5v1.595a8.367 8.367 0 00-2.385.68c-1.1.515-1.955 1.246-2.568 2.193-.614.946-.92 2.032-.92 3.258 0 1.398.306 2.516.92 3.355.634.817 1.384 1.419 2.251 1.806.716.312 1.616.624 2.702.936v8.003c-.664-.174-1.216-.465-1.655-.874-.74-.732-1.163-1.624-1.269-2.678H6.5c.021 1.226.36 2.312 1.015 3.258.655.925 1.554 1.656 2.695 2.194a8.86 8.86 0 002.29.656V27H15v-1.534c1.166-.093 2.202-.383 3.107-.87 1.12-.601 1.966-1.386 2.537-2.354.57-.968.856-2 .856-3.097 0-1.42-.317-2.537-.951-3.355-.635-.838-1.396-1.451-2.284-1.838-.835-.385-1.924-.77-3.265-1.154V5.154c.692.156 1.283.443 1.775.862.782.645 1.226 1.398 1.332 2.258h3.171c-.17-1.742-.92-3.14-2.252-4.193-1.082-.87-2.424-1.382-4.026-1.533V1zm-2.5 4.131c-.69.146-1.284.43-1.782.853-.761.624-1.142 1.505-1.142 2.645 0 .817.222 1.484.666 2a4.73 4.73 0 001.68 1.194c.176.066.368.136.578.208v-6.9zM15 15.544v7.423c.973-.117 1.766-.445 2.377-.983.804-.71 1.206-1.613 1.206-2.71 0-.86-.233-1.559-.698-2.097a4.457 4.457 0 00-1.744-1.225A18.069 18.069 0 0015 15.544z\" fill=\"#BBCBE4\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});