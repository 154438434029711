define("ember-svg-jar/inlined/ugc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M25.685 3.41l-.003 15.5c0 .68-.454 1.135-1.136 1.135H13.318l-5 3.523v-3.523H3.413c-.682 0-1.136-.454-1.136-1.136V3.41c0-.682.454-1.136 1.136-1.136h21.133c.682 0 1.14.454 1.14 1.136zM3.413 0C1.48 0 .003 1.477.003 3.41v15.5c0 1.93 1.478 3.408 3.41 3.408h2.632v4.96c.043.345.354.443.645.261C10.335 25 14 22.32 14 22.32h10.546c1.932 0 3.41-1.478 3.41-3.41V3.41c0-1.932-1.478-3.409-3.41-3.409H3.413zM18.14 7.552l.305-1.318c.101-.558-.254-1.065-.811-1.217-.558-.101-1.065.254-1.217.811l-.304 1.724h-2.991l.253-1.318c.102-.558-.253-1.065-.81-1.217-.558-.101-1.065.254-1.217.811l-.305 1.724H9.828c-.558 0-1.014.456-1.014 1.014 0 .557.456 1.014 1.014 1.014h.81l-.607 3.041H8.814c-.558 0-1.014.457-1.014 1.014 0 .558.456 1.014 1.014 1.014h.811l-.304 1.318c-.102.558.253 1.065.81 1.217h.204c.456 0 .912-.355 1.014-.811l.354-1.724h2.991l-.304 1.318c-.101.558.254 1.065.811 1.217h.203c.456 0 .912-.355 1.014-.811l.355-1.724h1.166c.557 0 1.014-.456 1.014-1.014 0-.557-.457-1.014-1.014-1.014h-.811l.608-3.041h1.217c.557 0 1.013-.457 1.013-1.014 0-.558-.456-1.014-1.013-1.014h-.812zm-6.031 5.07l.608-3.042h2.991l-.608 3.042H12.11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});