define("ember-svg-jar/inlined/lib-linkedin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.946 1.75c-.9 0-1.686.256-2.262.72a2.45 2.45 0 00-.928 1.909c0 1.461 1.309 2.592 3.039 2.66.049.015.1.024.15.025.935 0 1.73-.274 2.298-.759a2.525 2.525 0 00.891-1.955c-.082-1.47-1.39-2.6-3.188-2.6zm0 1.053c1.39 0 2.075.682 2.133 1.59a1.42 1.42 0 01-.519 1.112c-.347.297-.884.506-1.614.506-1.386 0-2.137-.752-2.137-1.632 0-.44.18-.802.537-1.09.356-.287.9-.486 1.6-.486zM2.283 8.135a.533.533 0 00-.533.533v15.445a.533.533 0 00.533.533h5.326a.532.532 0 00.532-.533V8.668a.533.533 0 00-.532-.533H2.283zm7.989 0a.533.533 0 00-.533.533v15.445a.533.533 0 00.533.533h5.326a.532.532 0 00.532-.533v-8.788c0-1.035.829-1.864 1.865-1.864 1.035 0 1.864.829 1.864 1.864v8.788a.532.532 0 00.532.533h5.326a.533.533 0 00.533-.533V15.06c0-2.198-.646-3.939-1.734-5.126-1.089-1.188-2.607-1.798-4.238-1.798-2.08 0-3.337.76-4.148 1.386v-.853a.532.532 0 00-.532-.533h-5.326zM2.815 9.2h4.261v14.38h-4.26V9.2zm7.99 0h4.26v1.406a.533.533 0 00.93.355s1.579-1.76 4.283-1.76c1.37 0 2.572.49 3.453 1.451.88.962 1.454 2.416 1.454 4.407v8.522h-4.261v-8.256a.533.533 0 00-.045-.218c-.118-1.505-1.35-2.711-2.884-2.711a2.938 2.938 0 00-2.93 2.93v8.254h-4.26V9.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});