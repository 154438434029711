define("ember-svg-jar/inlined/undo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_1560_9771)\"><path fill-rule=\"evenodd\" d=\"M0 3.945v7.637c0 .763.51 1.272 1.273 1.272h7.636c.764 0 1.273-.509 1.273-1.272 0-.764-.51-1.273-1.273-1.273H4.582c1.782-1.655 3.054-3.055 3.436-3.437a11.075 11.075 0 013.31-2.29c1.272-.51 2.545-.764 3.818-.764 1.272 0 2.672.254 3.818.764 1.272.509 2.418 1.272 3.309 2.29a11.075 11.075 0 012.29 3.31c.51 1.272.764 2.545.764 3.818 0 1.273-.254 2.672-.763 3.818-.51 1.273-1.273 2.418-2.291 3.309a11.075 11.075 0 01-3.31 2.29c-1.272.51-2.545.765-3.818.765-1.272 0-2.672-.255-3.818-.764-1.272-.51-2.418-1.273-3.309-2.291a9.61 9.61 0 01-1.4-1.782c-.382-.636-.763-1.272-1.018-2.036-.255-.637-1.018-1.018-1.655-.764-.636.255-1.018 1.018-.763 1.655.254.89.763 1.782 1.273 2.545.509.764 1.145 1.527 1.781 2.291a12.783 12.783 0 004.2 2.8c.764.255 1.528.51 2.418.637.764.127 1.655.254 2.419.254.763 0 1.654-.127 2.418-.255.764-.127 1.655-.381 2.418-.636 1.527-.636 3.055-1.527 4.2-2.8a12.784 12.784 0 002.8-4.2c.255-.763.51-1.527.636-2.418.128-.764.255-1.655.255-2.418 0-.764-.127-1.655-.255-2.418-.127-.764-.381-1.655-.636-2.419-.636-1.527-1.527-3.054-2.8-4.2a12.783 12.783 0 00-4.2-2.8c-.764-.254-1.527-.509-2.418-.636-.764-.127-1.655-.255-2.418-.255-.764 0-1.655.128-2.418.255-.764.127-1.655.382-2.419.636-1.527.637-3.054 1.528-4.2 2.8-.381.382-1.909 1.782-3.69 3.691V3.945c0-.763-.51-1.273-1.273-1.273C.509 2.672 0 3.182 0 3.945z\" clip-rule=\"evenodd\"/></g><defs><clipPath id=\"clip0_1560_9771\"><path d=\"M0 0h28v28H0z\"/></clipPath></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});