define("ember-svg-jar/inlined/skittle_yt_video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24 12c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z\" fill=\"red\"/><path d=\"M11.995 6.338s-5.058 0-6.327.33c-.68.19-1.24.75-1.43 1.439-.33 1.27-.33 3.898-.33 3.898s0 2.639.33 3.888c.19.69.74 1.24 1.43 1.43 1.28.34 6.327.34 6.327.34s5.067 0 6.337-.33c.69-.19 1.239-.73 1.419-1.43.34-1.26.34-3.888.34-3.888s.01-2.639-.34-3.908c-.18-.69-.73-1.24-1.42-1.42-1.269-.349-6.336-.35-6.336-.35zm-1.61 3.238l4.209 2.43-4.208 2.418V9.576z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});