define("ember-svg-jar/inlined/ig-story", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M12.844 1.156C12.844.518 13.362 0 14 0c7.732 0 14 6.268 14 14a13.95 13.95 0 01-3.526 9.29 1.156 1.156 0 11-1.73-1.534A11.64 11.64 0 0025.688 14c0-6.455-5.233-11.688-11.688-11.688a1.156 1.156 0 01-1.156-1.156zM5.767 4.074c.45.453.448 1.185-.005 1.635-.26.258-.508.53-.744.811a1.156 1.156 0 11-1.775-1.48c.281-.338.578-.662.89-.971a1.156 1.156 0 011.634.005zm-3.672 4.96c.609.191.947.84.755 1.45A11.682 11.682 0 002.312 14 1.156 1.156 0 010 14c0-1.466.226-2.88.645-4.211a1.156 1.156 0 011.45-.756zm-.424 8.761a1.156 1.156 0 011.508.632 11.723 11.723 0 003.341 4.555 1.156 1.156 0 01-1.48 1.775 14.035 14.035 0 01-4-5.454c-.242-.59.04-1.265.631-1.508zm19.018 7.233a1.156 1.156 0 01-.528 1.547A13.948 13.948 0 0114 28c-1.565 0-3.072-.257-4.48-.732a1.156 1.156 0 11.74-2.19c1.172.395 2.43.61 3.74.61 1.848 0 3.592-.428 5.142-1.189a1.156 1.156 0 011.547.529z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});