define("ember-svg-jar/inlined/social-linkedin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M.356 27.375H6.36V9.323H.356v18.052zM3.398.62C1.344.62 0 1.967 0 3.738c0 1.734 1.303 3.12 3.317 3.12h.04c2.093 0 3.396-1.386 3.396-3.12C6.713 1.967 5.45.62 3.398.62zM28 17.023v10.352h-6.002v-9.657c0-2.429-.868-4.083-3.04-4.083-1.66 0-2.644 1.115-3.08 2.194-.157.387-.198.924-.198 1.464v10.082H9.678s.079-16.357 0-18.052h6.002v2.558l-.04.059h.04v-.059c.797-1.227 2.222-2.983 5.41-2.983 3.95 0 6.91 2.58 6.91 8.125z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});