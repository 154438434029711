define("@latermedia/ember-later-analytics/utils/constants/index", ["exports", "@latermedia/ember-later-analytics/utils/constants/placeholder-url", "@latermedia/ember-later-analytics/utils/constants/hours-of-the-day", "@latermedia/ember-later-analytics/utils/constants/days-of-the-week"], function (_exports, _placeholderUrl, _hoursOfTheDay, _daysOfTheWeek) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "DAYS_OF_THE_WEEK", {
    enumerable: true,
    get: function () {
      return _daysOfTheWeek.default;
    }
  });
  Object.defineProperty(_exports, "HOURS_OF_THE_DAY", {
    enumerable: true,
    get: function () {
      return _hoursOfTheDay.default;
    }
  });
  Object.defineProperty(_exports, "PLACEHOLDER_URL", {
    enumerable: true,
    get: function () {
      return _placeholderUrl.default;
    }
  });
});