define("ember-svg-jar/inlined/align-center", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M21 19.54a1.46 1.46 0 110 2.92H7a1.46 1.46 0 110-2.92zm3.5-4.67a1.46 1.46 0 110 2.92h-21a1.46 1.46 0 110-2.92zM21 10.21a1.46 1.46 0 110 2.91H7a1.46 1.46 0 110-2.91zm3.5-4.67a1.46 1.46 0 110 2.92h-21a1.46 1.46 0 110-2.92z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});