define("ember-svg-jar/inlined/uppercase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M7 2.03a1.46 1.46 0 011.42 1.12l5.25 21.08A1.47 1.47 0 0112.6 26a1.34 1.34 0 01-.35 0 1.46 1.46 0 01-1.42-1.11L9.36 19H4.64l-1.47 5.85a1.462 1.462 0 01-2.84-.7l5.25-21A1.46 1.46 0 017 2.03zm14 0a1.46 1.46 0 011.42 1.12l5.25 21A1.47 1.47 0 0126.6 26a1.34 1.34 0 01-.35 0 1.46 1.46 0 01-1.42-1.11L23.36 19h-4.72l-1.47 5.85a1.462 1.462 0 01-2.84-.7l5.25-21A1.46 1.46 0 0121 2.03zM7 9.53L5.37 16h3.26L7 9.53zm14 0L19.37 16h3.26L21 9.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});